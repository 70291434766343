import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import MainContent from './components/MainContent';
import withHeader from './components/withHeader';
import Dashboard from './components/Dashboard';
import FAQ from './components/FAQ';
import Login from "./components/auth/Login";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import PublicRoute from "./components/auth/PublicRoute";

import {Provider} from 'react-redux';
import {persistor, store} from './redux/store';
import {PersistGate} from 'redux-persist/integration/react';
import Pricing from './components/Pricing';

import './index.css';
import {
    Automation,
    CalendlyScheduler,
    DemoRequestForm,
    JaanchAIOverview,
    Solutions,
    WhatsAppStyleChat
} from "./components/BrandLandingPage";
import ErrorBoundary from "./components/common/ErrorBoundary";

const LandingPage = () => {
    return (
        <>
            <MainContent/>
            <JaanchAIOverview/>
            {/* <GettingStarted/> */}
            {/* <NoCodeImplementations/> */}
        </>
    );
};

const LandingPageWithHeader = withHeader(LandingPage);
const SolutionsWithHeader = withHeader(Solutions);
const DemoRequestFormWithHeader = withHeader(DemoRequestForm);
const CalendlySchedulerWithHeader = withHeader(CalendlyScheduler);
const FAQWithHeader = withHeader(FAQ);
const PricingWithHeader = withHeader(Pricing);


function App() {
    return (
        <ErrorBoundary level="app">
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Router>
                        <div className="App">
                            <Routes>
                                <Route path="/" element={<PublicRoute><LandingPageWithHeader/></PublicRoute>}/>
                                <Route path="/solutions" element={<SolutionsWithHeader/>}/>
                                <Route path="/login" element={<PublicRoute><Login/></PublicRoute>}/>
                                <Route path="/dashboard" element={<ProtectedRoute><Dashboard/></ProtectedRoute>}/>
                                <Route path="/dashboard/:category" element={<ProtectedRoute><Dashboard/></ProtectedRoute>}/>
                                <Route path="/talk-to-expert" element={<CalendlySchedulerWithHeader/>}/>
                                <Route path="/request-demo" element={<DemoRequestFormWithHeader/>}/>
                                <Route path="/automations" element={<Automation/>}/>
                                <Route path="/whatsapp-chat" element={<WhatsAppStyleChat/>}/>
                                <Route path="/faq" element={<FAQWithHeader/>}/>
                                <Route path="/pricing" element={<PricingWithHeader/>}/>
                               

                            </Routes>
                        </div>
                    </Router>
                </PersistGate>
            </Provider>
        </ErrorBoundary>
    );
}

export default App;
