import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  TrendingUp, 
  Zap, 
  Database, 
  ArrowRight, 
  PieChart, 
  Layers,
  Building,
  Clock,
  CheckCircle2,
  Shield,
  Users,
  PanelTop
} from 'lucide-react';

const impactMetrics = [
  { metric: "5.2PB+", label: "Data Processing Capacity", icon: Database },
  { metric: "42%", label: "Faster Decision Making", icon: Clock },
  { metric: "3.8x", label: "Efficiency Potential", icon: TrendingUp }
];

const capabilities = [
  {
    title: "Data Integration",
    description: "Connect your scattered data sources into a single source of truth",
    icon: Layers,
    color: "#3B82F6"
  },
  {
    title: "Business Intelligence", 
    description: "Transform raw data into actionable insights for your team",
    icon: PieChart,
    color: "#8B5CF6"
  },
  {
    title: "Process Optimization", 
    description: "Identify bottlenecks and streamline operations workflows",
    icon: Zap,
    color: "#10B981"
  },
  {
    title: "Resource Planning", 
    description: "Optimize allocation and utilization of business resources",
    icon: Users,
    color: "#F59E0B"
  }
];

const industryUseCase = [
  {
    industry: "Manufacturing",
    icon: Building,
    possibilities: [
      "Unify production data across multiple facilities",
      "Optimize inventory and supply chain operations",
      "Track equipment efficiency and maintenance needs"
    ]
  },
  {
    industry: "Healthcare",
    icon: Shield,
    possibilities: [
      "Streamline patient data for better care coordination",
      "Optimize resource allocation across departments",
      "Improve inventory management of critical supplies"
    ]
  },
  {
    industry: "Retail & Distribution",
    icon: PanelTop,
    possibilities: [
      "Track inventory across multiple locations in real-time",
      "Analyze vendor performance and optimize procurement",
      "Identify top-performing products and categories"
    ]
  }
];

const features = [
  'Unified Data Integration',
  'Cross-Department Analytics',
  'Customized Business Intelligence',
  'Automated Insights Generation',
  'Vendor & Operations Optimization'
];

const JaanchAIOverview = () => {
  const [activeIndustry, setActiveIndustry] = useState(0);
  const [hoveredMetric, setHoveredMetric] = useState(null);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  // Get the current industry data
  const currentIndustry = industryUseCase[activeIndustry];
  // Get the icon component
  const IndustryIcon = currentIndustry.icon;

  return (
    <section className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-900 via-gray-900 to-black text-white">
      <div className="container mx-auto px-4 py-20">
        {/* Hero */}
        <motion.div 
          className="text-center mb-20"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.div 
            variants={itemVariants}
            className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-gradient-to-r from-blue-500/10 to-purple-500/10 border border-blue-500/20 mb-6"
          >
            <Layers className="w-4 h-4 text-blue-400" />
            <span className="text-blue-400 text-sm">Intelligent Data Platform</span>
          </motion.div>

          <motion.h1 
            variants={itemVariants}
            className="text-5xl md:text-7xl font-bold mb-6 tracking-tight"
          >
            <span className="bg-gradient-to-r from-blue-400 via-purple-400 to-emerald-400 bg-clip-text text-transparent">
              Business Data
            </span>
            <br />
            <span className="bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent">
              Transformed Into Clarity
            </span>
          </motion.h1>
          
          <motion.p
            variants={itemVariants}
            className="max-w-2xl mx-auto text-gray-400 text-lg mb-8"
          >
            JaanchAI streamlines scattered data sources into actionable insights that drive 
            smarter decisions across your entire organization.
          </motion.p>
        </motion.div>

        {/* Metrics */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-20">
          {impactMetrics.map((item, i) => {
            const MetricIcon = item.icon;
            return (
              <motion.div
                key={i}
                variants={itemVariants}
                whileHover={{ 
                  scale: 1.02,
                  transition: { type: "spring", stiffness: 400 }
                }}
                className="relative group"
                onMouseEnter={() => setHoveredMetric(i)}
                onMouseLeave={() => setHoveredMetric(null)}
              >
                <div className="absolute inset-0 bg-gradient-to-br from-blue-500/20 to-purple-500/20 rounded-2xl opacity-0 group-hover:opacity-100 transition-all duration-500" />
                <div className="relative p-8 bg-gray-800/50 backdrop-blur-xl border border-gray-700/50 rounded-2xl">
                  <motion.div 
                    animate={{
                      scale: hoveredMetric === i ? 1.1 : 1,
                      color: hoveredMetric === i ? "#60A5FA" : "#9CA3AF"
                    }}
                    transition={{ type: "spring", stiffness: 300 }}
                  >
                    <MetricIcon className="w-8 h-8 mb-4" />
                  </motion.div>
                  <div className="text-4xl font-bold mb-2">{item.metric}</div>
                  <div className="text-gray-400">{item.label}</div>
                </div>
              </motion.div>
            );
          })}
        </div>

        {/* Capabilities */}
        <div className="mb-20">
          <h2 className="text-3xl font-bold mb-12 text-center bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent">
            What JaanchAI Can Do
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {capabilities.map((capability, i) => {
              const CapabilityIcon = capability.icon;
              return (
                <motion.div
                  key={capability.title}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 + (i * 0.1) }}
                  className="p-6 bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 rounded-xl hover:bg-gray-800/70 transition-colors group"
                >
                  <div 
                    className="w-12 h-12 rounded-lg flex items-center justify-center mb-4 transition-colors"
                    style={{ backgroundColor: `${capability.color}20` }}
                  >
                    <CapabilityIcon 
                      className="w-6 h-6 transition-transform group-hover:scale-110" 
                      style={{ color: capability.color }} 
                    />
                  </div>
                  <h3 className="text-xl font-bold mb-2 text-white">{capability.title}</h3>
                  <p className="text-gray-400">{capability.description}</p>
                </motion.div>
              );
            })}
          </div>
        </div>
        
        {/* Industry Use Cases */}
        <div className="mb-20">
          <h2 className="text-3xl font-bold mb-8 text-center bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent">
            Possibilities Across Industries
          </h2>
          
          <div className="flex justify-center space-x-2 mb-8">
            {industryUseCase.map((industry, index) => {
              const ButtonIcon = industry.icon;
              return (
                <motion.button
                  key={index}
                  onClick={() => setActiveIndustry(index)}
                  className={`px-4 py-2 rounded-lg text-sm transition-colors flex items-center gap-2 ${
                    activeIndustry === index 
                      ? 'bg-blue-500/30 text-blue-300 border border-blue-500/30' 
                      : 'bg-gray-800/50 text-gray-400 border border-gray-700/30 hover:text-gray-200'
                  }`}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <ButtonIcon className="w-4 h-4" />
                  {industry.industry}
                </motion.button>
              );
            })}
          </div>
          
          <AnimatePresence mode="wait">
            <motion.div
              key={activeIndustry}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ type: "spring", stiffness: 100 }}
              className="bg-gray-800/30 backdrop-blur-sm border border-gray-700/30 rounded-2xl p-8 max-w-4xl mx-auto"
            >
              <div className="flex items-center gap-3 mb-6">
                <div 
                  className="w-12 h-12 rounded-full flex items-center justify-center" 
                  style={{ 
                    backgroundColor: activeIndustry === 0 ? "#3B82F620" : 
                                    activeIndustry === 1 ? "#10B98120" : "#F59E0B20" 
                  }}
                >
                  <IndustryIcon 
                    className="w-6 h-6" 
                    style={{ 
                      color: activeIndustry === 0 ? "#3B82F6" : 
                             activeIndustry === 1 ? "#10B981" : "#F59E0B" 
                    }} 
                  />
                </div>
                <h3 className="text-2xl font-bold">
                  {currentIndustry.industry}
                </h3>
              </div>
              
              <div className="space-y-4 pl-4">
                {currentIndustry.possibilities.map((possibility, i) => (
                  <motion.div
                    key={i}
                    initial={{ opacity: 0, x: -10 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.2 + (i * 0.1) }}
                    className="flex items-start gap-3"
                  >
                    <div 
                      className="w-1.5 h-1.5 rounded-full mt-2" 
                      style={{ 
                        backgroundColor: activeIndustry === 0 ? "#3B82F6" : 
                                        activeIndustry === 1 ? "#10B981" : "#F59E0B" 
                      }}
                    ></div>
                    <p className="text-gray-300">{possibility}</p>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          </AnimatePresence>
        </div>

        {/* Data Integration Visual */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="mb-20 p-8 bg-gray-800/30 backdrop-blur-sm border border-gray-700/30 rounded-2xl"
        >
          <h3 className="text-2xl font-bold mb-6 text-center">
            Seamless Data Integration
          </h3>
          
          <div className="relative h-24 md:h-32 max-w-3xl mx-auto">
            {/* Source systems */}
            <div className="absolute left-0 top-0 bottom-0 w-1/4 flex flex-col justify-between">
              {['ERP Systems', 'CRM Data', 'Sales Platforms', 'Legacy Systems'].map((source, i) => (
                <motion.div 
                  key={i}
                  initial={{ x: -20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.5 + (i * 0.1) }}
                  className="flex items-center gap-2 text-sm text-gray-400"
                >
                  <div className="w-2 h-2 rounded-full bg-blue-500" />
                  {source}
                </motion.div>
              ))}
            </div>
            
            {/* Connection lines */}
            <svg className="absolute inset-0 w-full h-full">
              {['ERP Systems', 'CRM Data', 'Sales Platforms', 'Legacy Systems'].map((_, i) => (
                <motion.path
                  key={i}
                  d={`M 110 ${(i * 32) + 10} C ${window.innerWidth / 4} ${(i * 32) + 10}, ${window.innerWidth / 2.5} 64, ${window.innerWidth / 2} 64`}
                  stroke="#3B82F6"
                  strokeWidth="1.5"
                  fill="none"
                  strokeDasharray="5,5"
                  initial={{ pathLength: 0, opacity: 0 }}
                  animate={{ pathLength: 1, opacity: 0.6 }}
                  transition={{ delay: 0.8 + (i * 0.1), duration: 1 }}
                />
              ))}
            </svg>
            
            {/* JaanchAI */}
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 0.7 }}
              className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20 bg-gradient-to-br from-blue-500 to-purple-600 rounded-full flex items-center justify-center"
            >
              <div className="text-white font-bold">JaanchAI</div>
            </motion.div>
            
            {/* Output dashboard */}
            <div className="absolute right-0 top-0 bottom-0 w-1/4 flex flex-col justify-between">
              {['Executive Dashboard', 'Operations Insights', 'Financial Analytics', 'Customer Intelligence'].map((output, i) => (
                <motion.div 
                  key={i}
                  initial={{ x: 20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 1.2 + (i * 0.1) }}
                  className="flex items-center justify-end gap-2 text-sm text-gray-400"
                >
                  {output}
                  <div className="w-2 h-2 rounded-full bg-emerald-500" />
                </motion.div>
              ))}
            </div>
            
            {/* Output lines */}
            <svg className="absolute inset-0 w-full h-full">
              {['Executive Dashboard', 'Operations Insights', 'Financial Analytics', 'Customer Intelligence'].map((_, i) => (
                <motion.path
                  key={i}
                  d={`M ${window.innerWidth / 2 + 10} 64 C ${window.innerWidth / 1.8} 64, ${window.innerWidth / 1.5} ${(i * 32) + 10}, ${window.innerWidth - 110} ${(i * 32) + 10}`}
                  stroke="#10B981"
                  strokeWidth="1.5"
                  fill="none"
                  strokeDasharray="5,5"
                  initial={{ pathLength: 0, opacity: 0 }}
                  animate={{ pathLength: 1, opacity: 0.6 }}
                  transition={{ delay: 1.4 + (i * 0.1), duration: 1 }}
                />
              ))}
            </svg>
          </div>
        </motion.div>
        
        {/* Platform Features */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="mb-20"
        >
          <h2 className="text-3xl font-bold mb-8 text-center bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent">
            Platform Features
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            <div className="space-y-4">
              <h4 className="text-xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent mb-6">
                Data Intelligence Platform
              </h4>
              {features.map((feature, i) => (
                <motion.div
                  key={i}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.4 + (i * 0.1) }}
                  className="flex items-center gap-3 text-gray-400 group"
                >
                  <div className="h-px w-6 bg-gray-700 group-hover:w-12 group-hover:bg-blue-400 transition-all duration-300" />
                  {feature}
                </motion.div>
              ))}
              
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.8 }}
                className="pt-6 mt-6 border-t border-gray-800/60"
              >
                <div className="flex items-center gap-2 text-sm text-gray-500">
                  <CheckCircle2 className="w-4 h-4 text-emerald-500" />
                  <span>Works with your existing data infrastructure</span>
                </div>
              </motion.div>
            </div>
            
            <div className="bg-gray-800/50 backdrop-blur-sm border border-gray-700/30 rounded-xl p-6">
              <h4 className="text-lg font-semibold mb-4 text-white">Technology Stack</h4>
              <div className="space-y-3">
                {[
                  "Advanced Data Processing Engine",
                  "Machine Learning Algorithms",
                  "Real-time Analytics Dashboard",
                  "Secure API Integrations",
                  "Customizable Business Rules"
                ].map((tech, i) => (
                  <motion.div
                    key={i}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.5 + (i * 0.1) }}
                    className="flex items-center gap-2 text-gray-300"
                  >
                    <div className="w-1.5 h-1.5 rounded-full bg-blue-500" />
                    <span>{tech}</span>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        </motion.div>

        {/* CTA */}
        <motion.div 
          className="text-center"
        >
          <motion.h3
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-2xl font-bold mb-6"
          >
            Ready to transform your business data?
          </motion.h3>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="group relative px-8 py-3 bg-gradient-to-r from-blue-500 to-purple-500 rounded-xl font-medium text-white flex items-center gap-2 mx-auto overflow-hidden"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-purple-600 translate-y-[100%] group-hover:translate-y-0 transition-transform" />
            <span className="relative">Schedule a Demo</span>
            <ArrowRight className="w-4 h-4 relative group-hover:translate-x-1 transition-transform" />
          </motion.button>
        </motion.div>
      </div>
    </section>
  );
};

export default JaanchAIOverview;