import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import { 
  FaStore, 
  FaHospital, 
  FaUtensils, 
  FaIndustry, 
  FaBriefcase, 
  FaDatabase, 
  FaChartBar,
  FaTruck,
  FaSearchDollar,
  FaWarehouse
} from 'react-icons/fa';
import { ArrowRight, Database, Activity, Zap } from 'lucide-react';

const industries = [
  { 
    title: "Retail & Commerce", 
    icon: FaStore, 
    color: "#3B82F6", 
    desc: "Sales & inventory insights",
    features: ["Performance metrics", "Stock optimization", "Customer analysis"]
  },
  { 
    title: "Healthcare", 
    icon: FaHospital, 
    color: "#10B981", 
    desc: "Hospital & clinic data",
    features: ["Patient metrics", "Resource allocation", "Treatment analytics"]
  },
  { 
    title: "Food & Beverage", 
    icon: FaUtensils, 
    color: "#8B5CF6", 
    desc: "Restaurant & catering",
    features: ["Operations analytics", "Inventory management", "Profit analysis"]
  },
  { 
    title: "Manufacturing", 
    icon: FaIndustry, 
    color: "#EF4444", 
    desc: "Production & supply chain",
    features: ["Process efficiency", "Quality control", "Resource optimization"]
  },
  { 
    title: "Professional Services", 
    icon: FaBriefcase, 
    color: "#F59E0B", 
    desc: "Business operations",
    features: ["Client analytics", "Resource management", "Growth insights"]
  }
];

const features = [
  {
    icon: FaDatabase,
    title: "Smart Data Integration",
    description: "Transform scattered business data into a single source of truth"
  },
  {
    icon: FaChartBar,
    title: "Dynamic Analytics",
    description: "Get unified insights across inventory, operations, and financials"
  },
  {
    icon: FaSearchDollar,
    title: "Actionable Intelligence",
    description: "Identify opportunities, trends, and areas for improvement"
  }
];

const capabilities = [
  {
    title: "Inventory Intelligence",
    description: "Track stock levels, movement, and valuation with precision",
    icon: FaWarehouse
  },
  {
    title: "Business Performance",
    description: "Monitor KPIs, track growth, and identify profit drivers",
    icon: Activity
  },
  {
    title: "Supply Chain Insights",
    description: "Optimize vendor relationships and procurement processes",
    icon: FaTruck
  }
];

const HeroBanner = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const controls = useAnimation();
  const ref = useRef(null);
  const inView = useInView(ref);

  useEffect(() => {
    if (inView) controls.start('visible');
    
    if (!isHovered) {
      const interval = setInterval(() => {
        setActiveIndex(prev => (prev + 1) % industries.length);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [controls, inView, isHovered]);

  const buttonVariants = {
    hover: {
      scale: 1.05,
      transition: {
        duration: 0.2,
        ease: "easeInOut"
      }
    },
    tap: {
      scale: 0.95
    }
  };

  return (
    <section className="relative min-h-screen overflow-hidden bg-gradient-to-br from-gray-900 to-black">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top_right,_var(--tw-gradient-stops))] from-blue-500/10 via-purple-500/5 to-transparent" />
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_bottom_left,_var(--tw-gradient-stops))] from-emerald-500/5 via-transparent to-transparent" />
      </div>

      <div className="container relative z-10 mx-auto px-4 pt-32 pb-20">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center"
        >
          {/* Badge */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="mb-8 inline-flex items-center gap-2 rounded-full bg-gradient-to-r from-blue-500/10 to-purple-500/10 px-4 py-2 backdrop-blur-sm"
          >
            <Database className="h-4 w-4 text-blue-400" />
            <span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
              Intelligent Data Platform
            </span>
          </motion.div>

          {/* Main Heading */}
          <motion.h1 
            className="mb-6 bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-5xl font-bold leading-tight text-transparent sm:text-6xl md:text-7xl"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            Turn Business Data Into
            <span className="block bg-gradient-to-r from-blue-400 via-purple-400 to-emerald-400 bg-clip-text text-transparent">
              Powerful Decisions
            </span>
          </motion.h1>

          {/* Description */}
          <motion.p 
            className="mx-auto mb-12 max-w-2xl text-lg text-gray-400"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            JaanchAI transforms cluttered databases into clear insights. Whether you track inventory, 
            analyze sales, or monitor vendor performance, get the clarity you need to make smarter decisions.
          </motion.p>

          {/* CTA Buttons */}
          <motion.div 
            className="mb-20 flex flex-col sm:flex-row justify-center gap-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
          >
            <motion.button
              variants={buttonVariants}
              whileHover="hover"
              whileTap="tap"
              className="group relative overflow-hidden rounded-xl bg-gradient-to-r from-blue-500 to-purple-500 px-8 py-3 text-white shadow-lg transition-all hover:shadow-purple-500/25"
            >
              <div className="absolute inset-0 bg-white/10 opacity-0 transition-opacity group-hover:opacity-100" />
              <span className="flex items-center gap-2">
                Start Free Trial
                <ArrowRight className="h-4 w-4 transition-transform group-hover:translate-x-1" />
              </span>
            </motion.button>

            <motion.button
              variants={buttonVariants}
              whileHover="hover"
              whileTap="tap"
              className="group rounded-xl border border-gray-700 bg-gray-800/50 px-8 py-3 text-gray-300 backdrop-blur-sm transition-all hover:border-purple-500/50 hover:bg-gray-800"
            >
              Schedule Demo
            </motion.button>
          </motion.div>
        </motion.div>

        {/* Core Capabilities */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
          className="mx-auto mb-24 max-w-4xl"
        >
          <h2 className="text-center text-2xl font-bold text-white mb-10">
            Streamline Your <span className="text-blue-400">Business Data</span>
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {capabilities.map((item, index) => (
              <motion.div 
                key={index} 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.7 + index * 0.1 }}
                className="flex flex-col items-center p-5 rounded-xl bg-gray-800/30 border border-gray-700/50 backdrop-blur-sm hover:bg-gray-800/50 transition-colors"
              >
                <div className="mb-4 rounded-full bg-blue-500/20 p-3.5">
                  {typeof item.icon === 'function' ? (
                    <item.icon className="h-6 w-6 text-blue-400" />
                  ) : (
                    <item.icon className="h-6 w-6 text-blue-400" />
                  )}
                </div>
                <h3 className="mb-2 text-center text-lg font-medium text-white">{item.title}</h3>
                <p className="text-center text-sm text-gray-400">{item.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Industries Showcase */}
        <motion.div 
          className="relative mx-auto h-96 max-w-3xl mb-24"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.7 }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <h2 className="text-center text-2xl font-bold text-white mb-10">
            <span className="text-purple-400">Solutions</span> For Every Industry
          </h2>
          
          {/* Background glow */}
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="h-72 w-72 animate-pulse rounded-full bg-gradient-to-br from-blue-500/20 to-purple-600/20 blur-3xl" />
          </div>

          {/* Industries circle */}
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="relative h-64 w-64">
              {industries.map((industry, index) => (
                <motion.div
                  key={industry.title}
                  className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform transition-all duration-500 ${
                    index === activeIndex ? 'scale-100 opacity-100' : 'scale-90 opacity-0'
                  }`}
                  style={{
                    transform: `rotate(${(index - activeIndex) * 72}deg) translateY(-120px) translateX(-50%)`,
                  }}
                >
                  <div className="flex flex-col items-center">
                    <div 
                      className={`mb-3 rounded-xl bg-gradient-to-br p-4 group cursor-pointer hover:scale-110 transition-transform`} 
                      style={{ backgroundColor: `${industry.color}20` }}
                      onClick={() => setActiveIndex(index)}
                    >
                      <industry.icon className="h-8 w-8" style={{ color: industry.color }} />
                    </div>
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      className="text-center w-36"
                    >
                      <h3 className="text-lg font-semibold text-white">{industry.title}</h3>
                      <p className="text-sm text-gray-400">{industry.desc}</p>
                    </motion.div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </motion.div>

        {/* Features Grid */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8 }}
          className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3"
        >
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8 + index * 0.1 }}
              className="group rounded-2xl bg-gray-800/50 p-6 backdrop-blur-sm hover:bg-gray-800/80 transition-colors"
            >
              <div className="mb-4 inline-block rounded-xl bg-blue-500/10 p-3 text-blue-400 group-hover:bg-blue-500/20 transition-colors">
                <feature.icon className="h-6 w-6" />
              </div>
              <h3 className="mb-2 text-lg font-semibold text-white">{feature.title}</h3>
              <p className="text-gray-400">{feature.description}</p>
            </motion.div>
          ))}
        </motion.div>
        
        {/* Bottom CTA */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1 }}
          className="mt-20 text-center"
        >
          <h2 className="text-2xl font-bold text-white mb-5">
            Ready to transform your business data?
          </h2>
          <motion.button
            variants={buttonVariants}
            whileHover="hover"
            whileTap="tap"
            className="inline-flex items-center gap-2 px-8 py-3 rounded-xl bg-gradient-to-r from-blue-500 to-purple-500 text-white font-medium"
          >
            <Zap className="h-5 w-5" />
            Get Started Today
          </motion.button>
        </motion.div>
      </div>
    </section>
  );
};

export default HeroBanner;