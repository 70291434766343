import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import {  Bot, Sparkles, Clock, ArrowRight, Brain, Users, Video } from 'lucide-react';

const benefits = [
  {
    icon: Brain,
    title: "AI Strategy",
    description: "Customize AI solutions for your business needs"
  },
  {
    icon: Users,
    title: "Expert Guidance",
    description: "Direct access to our AI specialists"
  },
  {
    icon: Video,
    title: "Live Demo",
    description: "See JaanchAI in action"
  }
];

const CalendlyScheduler = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  return (
    <section className="relative min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-900 via-gray-900 to-black overflow-hidden py-20">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,_var(--tw-gradient-stops))] from-blue-500/10 via-purple-500/5 to-transparent" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,_var(--tw-gradient-stops))] from-purple-500/5 via-transparent to-transparent" />
      </div>

      <div className="container relative mx-auto px-4">
        <motion.div className="text-center mb-16">
          <motion.div 
            className="inline-flex items-center gap-2 px-4 py-2 rounded-xl bg-blue-500/10 border border-blue-500/20 mb-6"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Bot className="w-5 h-5 text-blue-400" />
            <span className="text-blue-400 text-sm">Expert Consultation</span>
          </motion.div>

          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent">
              Transform Your Business
            </span>
            <br />
            <span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
              With AI Analytics
            </span>
          </h2>
        </motion.div>

        <div className="grid lg:grid-cols-2 gap-12 max-w-6xl mx-auto">
          <div className="space-y-8">
            {/* Time Estimate */}
            <motion.div 
              className="flex items-center gap-4 p-4 bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 rounded-xl"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
            >
              <div className="p-3 bg-blue-500/10 rounded-lg">
                <Clock className="w-6 h-6 text-blue-400" />
              </div>
              <div>
                <div className="text-sm text-gray-400">Duration</div>
                <div className="text-white font-medium">30 Minutes</div>
              </div>
            </motion.div>

            {/* Benefits */}
            <div className="space-y-4">
              {benefits.map((benefit, index) => (
                <motion.div
                  key={index}
                  className="p-4 bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 rounded-xl"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <div className="flex items-center gap-4">
                    <div className="p-3 bg-blue-500/10 rounded-lg">
                      <benefit.icon className="w-6 h-6 text-blue-400" />
                    </div>
                    <div>
                      <div className="font-medium text-white">{benefit.title}</div>
                      <div className="text-sm text-gray-400">{benefit.description}</div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>

            {/* CTA */}
            <motion.div 
              className="p-6 bg-gradient-to-br from-blue-500/20 to-purple-500/20 rounded-xl border border-blue-500/20"
              whileHover={{ scale: 1.02 }}
            >
              <div className="flex items-center gap-3 mb-3">
                <Sparkles className="w-5 h-5 text-blue-400" />
                <div className="text-lg font-medium text-white">Ready to Get Started?</div>
              </div>
              <p className="text-gray-400 mb-4">
                Schedule your free consultation and discover how JaanchAI can transform your business operations.
              </p>
              <motion.button
                className="flex items-center gap-2 text-sm text-blue-400"
                whileHover={{ x: 5 }}
              >
                Learn more 
                <ArrowRight className="w-4 h-4" />
              </motion.button>
            </motion.div>
          </div>

          {/* Calendly Widget */}
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            className="bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 rounded-xl p-6 h-[700px]"
          >
            <div 
              className="calendly-inline-widget" 
              data-url="https://calendly.com/gopal-jaanch/30min" 
              style={{ width: '100%', height: '100%' }} 
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default CalendlyScheduler;