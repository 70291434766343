import React, { useState } from 'react';
import { 
  FaCheck, 
  FaMedal, 
  FaQuestionCircle, 
  FaInfoCircle, 
  FaChartLine, 
  FaDatabase, 
  FaServer, 
  FaRegClock,
  FaToolbox,
  FaRegLightbulb
} from 'react-icons/fa';
import { IoChevronForwardOutline, IoClose } from 'react-icons/io5';

const Pricing = () => {
  const [billingPeriod, setBillingPeriod] = useState('annual');
  const [showEarlyAdopter, setShowEarlyAdopter] = useState(true);
  const [showComparisonModal, setShowComparisonModal] = useState(false);
  const [activeFaq, setActiveFaq] = useState(null);
  const [showCalculator, setShowCalculator] = useState(false);
  
  // FAQ data
  const faqs = [
    {
      question: "What's included in the implementation fee?",
      answer: "The implementation fee covers the initial setup of your JaanchAI environment, data integration, schema optimization, model training, and a dedicated onboarding specialist to ensure your success. For annual commitments, we offer a 25% discount on the implementation fee, and for early adopters, we waive it entirely."
    },
    {
      question: "Can I upgrade my plan later?",
      answer: "Yes, you can upgrade your plan at any time. When upgrading, we'll prorate your existing subscription and apply any unused portion to your new plan. Your new billing cycle will start from the date of the upgrade."
    },
    {
      question: "Do you offer industry-specific solutions?",
      answer: "Yes, JaanchAI is designed to work across industries with pre-built models and KPIs for retail, manufacturing, healthcare, and more. Our implementation process includes customizing the platform to your industry's specific requirements."
    },
    {
      question: "How long does implementation take?",
      answer: "The typical implementation timeline ranges from 2 weeks for the Professional plan to 4-8 weeks for Enterprise plans, depending on data complexity and customization requirements. Our goal is to get you to value as quickly as possible."
    }
  ];

  const pricingTiers = [
    {
      title: 'Professional',
      subtitle: 'Transform your data',
      description: 'For growing businesses ready to make data-driven decisions',
      icon: FaChartLine,
      pricing: {
        monthly: '₹25,000',
        quarterly: '₹23,000',
        biannual: '₹21,250',
        annual: '₹20,000',
      },
      earlyAdopter: {
        monthly: '₹17,000',
        savings: '₹36,000 + ₹75,000'
      },
      implementationFee: '₹75,000',
      discountedImplementationFee: '₹56,250',
      savings: {
        monthly: '0%',
        quarterly: '8%',
        biannual: '15%',
        annual: '20%',
      },
      additionalBenefits: {
        monthly: 'Standard support (8hr response time)',
        quarterly: 'Priority email support (4hr response time)',
        biannual: 'Quarterly strategy session with data analyst',
        annual: 'Dedicated success manager'
      },
      topFeatures: [
        'Up to 500,000 records',
        '5 data sources',
        'Full data cleaning & transformation'
      ],
      allFeatures: [
        'Up to 500,000 records',
        '5 data sources',
        'Full data cleaning & transformation',
        '20+ industry-specific KPIs',
        '10 user accounts with role-based access',
        'Data visualization dashboard',
        'Email exports and reports',
        'Basic API access',
        'Standard security features',
        '99.5% uptime SLA'
      ],
      color: 'gray',
      bgGradient: 'from-gray-50 to-gray-100',
      darkBgGradient: 'from-gray-900/20 to-gray-800/20',
      borderColor: 'border-gray-200',
      darkBorderColor: 'border-gray-800/30',
      buttonBg: 'bg-gray-600 hover:bg-gray-700',
      buttonLight: 'bg-gray-100 hover:bg-gray-200 text-gray-700 dark:bg-gray-900/50 dark:hover:bg-gray-800 dark:text-gray-300'
    },
    {
      title: 'Business',
      subtitle: 'Enhance your analysis',
      description: 'For established companies with complex data needs',
      icon: FaDatabase,
      isPopular: true,
      pricing: {
        monthly: '₹45,000',
        quarterly: '₹41,400',
        biannual: '₹38,250',
        annual: '₹36,000',
      },
      earlyAdopter: {
        monthly: '₹30,600',
        savings: '₹64,800 + ₹125,000'
      },
      implementationFee: '₹125,000',
      discountedImplementationFee: '₹93,750',
      savings: {
        monthly: '0%',
        quarterly: '8%',
        biannual: '15%',
        annual: '20%',
      },
      additionalBenefits: {
        monthly: 'Priority support (4hr response time)',
        quarterly: '2-hour response time + weekend support',
        biannual: 'Monthly strategy session with data scientist',
        annual: 'Custom model development & quarterly review'
      },
      topFeatures: [
        'Up to 2 million records',
        'Unlimited data sources',
        'Advanced modeling & custom integrations'
      ],
      allFeatures: [
        'Everything in Professional, plus:',
        'Up to 2 million records',
        'Unlimited data sources',
        'Advanced modeling & custom integrations',
        'Unlimited KPIs with custom metrics',
        '25 user accounts with granular permissions',
        'Advanced visualizations & custom dashboards',
        'Scheduled reports & alerts',
        'Full API access',
        'Enhanced security with SSO',
        '99.9% uptime SLA'
      ],
      color: 'gray',
      bgGradient: 'from-gray-50 to-gray-100',
      darkBgGradient: 'from-gray-900/20 to-gray-800/20',
      borderColor: 'border-gray-200',
      darkBorderColor: 'border-gray-800/30',
      buttonBg: 'bg-gray-600 hover:bg-gray-700',
      buttonLight: 'bg-gray-100 hover:bg-gray-200 text-gray-700 dark:bg-gray-900/50 dark:hover:bg-gray-800 dark:text-gray-300'
    },
    {
      title: 'Enterprise',
      subtitle: 'Scale your intelligence',
      description: 'For large organizations with advanced requirements',
      icon: FaServer,
      pricing: {
        monthly: '₹75,000+',
        quarterly: '₹69,000+',
        biannual: '₹63,750+',
        annual: '₹60,000+',
      },
      earlyAdopter: {
        monthly: '₹54,000+',
        savings: 'Custom'
      },
      implementationFee: '₹200,000+',
      discountedImplementationFee: '₹150,000+',
      savings: {
        monthly: '0%',
        quarterly: '8%',
        biannual: '15%',
        annual: '20%',
      },
      additionalBenefits: {
        monthly: 'Dedicated support team',
        quarterly: '1-hour response time + 24/7 critical support',
        biannual: 'Quarterly executive review & roadmap input',
        annual: 'Dedicated development resources & custom SLA'
      },
      topFeatures: [
        'Unlimited data capacity',
        'Dedicated environment',
        'Custom integrations & workflows'
      ],
      allFeatures: [
        'Everything in Business, plus:',
        'Unlimited data capacity',
        'Dedicated environment & infrastructure',
        'White-labeling options',
        'Unlimited users with organizational hierarchy',
        'Custom integrations & workflows',
        'Advanced security & compliance features',
        'Multi-region deployment options',
        'Custom SLAs with financially backed guarantees',
        'On-premises deployment option',
        'Executive business reviews'
      ],
      color: 'gray',
      bgGradient: 'from-gray-50 to-gray-100',
      darkBgGradient: 'from-gray-900/20 to-gray-800/20',
      borderColor: 'border-gray-200',
      darkBorderColor: 'border-gray-800/30',
      buttonBg: 'bg-gray-600 hover:bg-gray-700',
      buttonLight: 'bg-gray-100 hover:bg-gray-200 text-gray-700 dark:bg-gray-900/50 dark:hover:bg-gray-800 dark:text-gray-300'
    }
  ];

  // Helper functions
  const getCommitmentLabel = () => {
    switch(billingPeriod) {
      case 'monthly': return '/month';
      case 'quarterly': return '/month, billed quarterly';
      case 'biannual': return '/month, billed every 6 months';
      case 'annual': return '/month, billed annually';
      default: return '/month';
    }
  };

  const getTotalCommitment = (price) => {
    const numericPrice = parseFloat(price.replace(/[^\d.]/g, ''));
    if (isNaN(numericPrice)) return price;
    
    let total;
    switch(billingPeriod) {
      case 'monthly': total = numericPrice; break;
      case 'quarterly': total = numericPrice * 3; break;
      case 'biannual': total = numericPrice * 6; break;
      case 'annual': total = numericPrice * 12; break;
      default: total = numericPrice;
    }
    
    return `₹${total.toLocaleString('en-IN')}`;
  };

  const getImplementationFee = (plan) => {
    if (showEarlyAdopter && billingPeriod === 'annual') {
      return 'Waived';
    }
    
    if (billingPeriod === 'annual') {
      return plan.discountedImplementationFee;
    }
    
    return plan.implementationFee;
  };

  const getSavingsPercentage = (plan) => {
    const value = plan.savings[billingPeriod];
    const percentage = parseFloat(value);
    
    return isNaN(percentage) ? 0 : percentage;
  };

  // ROI Calculator component
  const ROICalculator = () => {
    const [employees, setEmployees] = useState(50);
    const [analysts, setAnalysts] = useState(2);
    const [dataSize, setDataSize] = useState(1);
    
    // Calculate time savings (hours per week)
    const timeSavings = analysts * 8 + employees * 1;
    // Calculate money savings per year (assuming $50/hour average)
    const moneySavings = timeSavings * 50 * 52;
    // ROI based on Business tier annual plan
    const annualCost = 36000 * 12 + 125000;
    const roi = ((moneySavings - annualCost) / annualCost * 100).toFixed(0);
    
    return (
      <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-gray-900 dark:text-white">ROI Calculator</h3>
          <button onClick={() => setShowCalculator(false)} className="text-gray-400 hover:text-gray-500">
            <IoClose className="w-5 h-5" />
          </button>
        </div>
        
        <div className="space-y-4 mb-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              How many employees use data regularly?
            </label>
            <input 
              type="range" 
              min="10" 
              max="500" 
              value={employees} 
              onChange={(e) => setEmployees(parseInt(e.target.value))}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
            />
            <div className="flex justify-between text-sm text-gray-500 mt-1">
              <span>10</span>
              <span>{employees} employees</span>
              <span>500</span>
            </div>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              How many data analysts do you employ?
            </label>
            <input 
              type="range" 
              min="0" 
              max="10" 
              value={analysts} 
              onChange={(e) => setAnalysts(parseInt(e.target.value))}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
            />
            <div className="flex justify-between text-sm text-gray-500 mt-1">
              <span>0</span>
              <span>{analysts} analysts</span>
              <span>10+</span>
            </div>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Data volume (in millions of records)
            </label>
            <input 
              type="range" 
              min="0.1" 
              max="5" 
              step="0.1"
              value={dataSize} 
              onChange={(e) => setDataSize(parseFloat(e.target.value))}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
            />
            <div className="flex justify-between text-sm text-gray-500 mt-1">
              <span>0.1M</span>
              <span>{dataSize.toFixed(1)}M records</span>
              <span>5M+</span>
            </div>
          </div>
        </div>
        
        <div className="bg-gray-50 dark:bg-gray-700/30 rounded-lg p-4">
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <div className="text-sm text-gray-500 dark:text-gray-400 mb-1">Weekly time savings</div>
              <div className="text-2xl font-bold text-gray-900 dark:text-white">{timeSavings} hours</div>
            </div>
            <div>
              <div className="text-sm text-gray-500 dark:text-gray-400 mb-1">Annual cost savings</div>
              <div className="text-2xl font-bold text-green-600 dark:text-green-400">${moneySavings.toLocaleString()}</div>
            </div>
          </div>
          
          <div className="flex items-center justify-between p-3 bg-purple-50 dark:bg-purple-900/20 rounded-lg border border-purple-100 dark:border-purple-800/30">
            <div className="text-sm font-medium text-purple-800 dark:text-purple-300">Potential ROI</div>
            <div className="text-xl font-bold text-purple-700 dark:text-purple-300">{roi}%</div>
          </div>
          
          <div className="mt-4 text-sm text-gray-500 dark:text-gray-400">
            Based on industry averages. Your actual ROI may vary depending on your specific use case and data complexity.
          </div>
        </div>
        
        <div className="mt-6 flex justify-center">
          <button 
            onClick={() => setShowEarlyAdopter(true)}
            className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
          >
            Get Started with Business Plan
          </button>
        </div>
      </div>
    );
  };

  // Feature comparison modal
  const FeatureComparisonModal = () => {
    const allFeatures = [
      {
        category: "Data Capacity & Sources",
        features: [
          { name: "Record capacity", professional: "500,000", business: "2 million", enterprise: "Unlimited" },
          { name: "Data sources", professional: "5", business: "Unlimited", enterprise: "Unlimited" },
          { name: "Historical data retention", professional: "12 months", business: "36 months", enterprise: "Unlimited" }
        ]
      },
      {
        category: "Analytics Capabilities",
        features: [
          { name: "Automated data cleaning", professional: "✓", business: "✓", enterprise: "✓" },
          { name: "Pre-defined KPIs", professional: "20+", business: "50+", enterprise: "Unlimited" },
          { name: "Custom KPIs", professional: "Limited", business: "✓", enterprise: "✓" },
          { name: "AI-generated insights", professional: "Basic", business: "Advanced", enterprise: "Enterprise-grade" },
          { name: "Custom model development", professional: "—", business: "Limited", enterprise: "✓" }
        ]
      },
      {
        category: "User Access & Collaboration",
        features: [
          { name: "User accounts", professional: "10", business: "25", enterprise: "Unlimited" },
          { name: "Role-based access", professional: "Basic", business: "Advanced", enterprise: "Custom hierarchy" },
          { name: "Dashboard sharing", professional: "✓", business: "✓", enterprise: "✓" },
          { name: "Collaboration features", professional: "Basic", business: "Advanced", enterprise: "Enterprise-grade" }
        ]
      },
      {
        category: "Security & Compliance",
        features: [
          { name: "Data encryption", professional: "✓", business: "✓", enterprise: "✓" },
          { name: "SSO integration", professional: "—", business: "✓", enterprise: "✓" },
          { name: "Audit logs", professional: "30 days", business: "1 year", enterprise: "Custom retention" },
          { name: "Custom security policies", professional: "—", business: "Limited", enterprise: "✓" },
          { name: "Compliance certifications", professional: "Basic", business: "Advanced", enterprise: "Custom" }
        ]
      },
      {
        category: "Support & Services",
        features: [
          { name: "Response time", professional: "8 hours", business: "4 hours", enterprise: "1 hour" },
          { name: "Dedicated success manager", professional: "Annual only", business: "✓", enterprise: "✓ (Team)" },
          { name: "Training sessions", professional: "1", business: "4", enterprise: "Unlimited" },
          { name: "Business reviews", professional: "Annual", business: "Quarterly", enterprise: "Monthly" }
        ]
      }
    ];
    
    return (
      <div className="fixed inset-0 bg-black/50 dark:bg-black/70 flex items-center justify-center z-50 p-4">
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-2xl max-w-5xl w-full max-h-[90vh] overflow-hidden flex flex-col">
          <div className="flex items-center justify-between px-6 py-4 border-b border-gray-200 dark:border-gray-700">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">JaanchAI Plan Comparison</h3>
            <button 
              onClick={() => setShowComparisonModal(false)}
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            >
              <IoClose className="w-6 h-6" />
            </button>
          </div>
          
          <div className="overflow-auto p-6">
            <div className="grid grid-cols-4 gap-4">
              <div className="sticky top-0 bg-white dark:bg-gray-800 z-10 pt-10">
                <div className="font-medium text-gray-500 dark:text-gray-400">Features</div>
              </div>
              
              {pricingTiers.map((tier, index) => (
                <div key={index} className="sticky top-0 bg-white dark:bg-gray-800 z-10">
                  <div className={`rounded-lg p-4 ${
                    tier.isPopular 
                      ? 'bg-gray-50 dark:bg-gray-900/30 border border-gray-200 dark:border-gray-800/50' 
                      : 'bg-gray-50 dark:bg-gray-700/30 border border-gray-200 dark:border-gray-700'
                  }`}>
                    <div className="font-medium text-gray-900 dark:text-white">{tier.title}</div>
                    <div className="text-lg font-bold text-gray-900 dark:text-white">
                      {tier.pricing.annual}
                      <span className="text-sm font-normal text-gray-500 dark:text-gray-400">/mo</span>
                    </div>
                  </div>
                </div>
              ))}
              
              {allFeatures.map((category, catIndex) => (
                <React.Fragment key={catIndex}>
                  <div className="col-span-4 mt-6 mb-2">
                    <h4 className="text-lg font-medium text-gray-900 dark:text-white">{category.category}</h4>
                    <div className="h-0.5 w-full bg-gray-100 dark:bg-gray-700 mt-2"></div>
                  </div>
                  
                  {category.features.map((feature, featureIndex) => (
                    <React.Fragment key={featureIndex}>
                      <div className="py-3 text-sm text-gray-800 dark:text-gray-200">{feature.name}</div>
                      
                      <div className="py-3 text-center">
                        <span className={`text-sm ${
                          feature.professional === "—" 
                            ? "text-gray-400 dark:text-gray-500" 
                            : "text-gray-800 dark:text-gray-200 font-medium"
                        }`}>
                          {feature.professional}
                        </span>
                      </div>
                      
                      <div className="py-3 text-center">
                        <span className={`text-sm ${
                          feature.business === "—" 
                            ? "text-gray-400 dark:text-gray-500" 
                            : "text-gray-600 dark:text-gray-300 font-medium"
                        }`}>
                          {feature.business}
                        </span>
                      </div>
                      
                      <div className="py-3 text-center">
                        <span className={`text-sm ${
                          feature.enterprise === "—" 
                            ? "text-gray-400 dark:text-gray-500" 
                            : "text-gray-600 dark:text-gray-300 font-medium"
                        }`}>
                          {feature.enterprise}
                        </span>
                      </div>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </div>
          </div>
          
          <div className="border-t border-gray-200 dark:border-gray-700 p-4 bg-gray-50 dark:bg-gray-800">
            <div className="flex justify-end">
              <button 
                onClick={() => setShowComparisonModal(false)}
                className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section className="bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 py-20">
      <div className="container mx-auto px-4">
        {/* Header section */}
        <div className="text-center max-w-3xl mx-auto mb-12">
          <div className="inline-flex items-center gap-2 px-3 py-1 rounded-full bg-indigo-50 dark:bg-indigo-900/30 border border-indigo-100 dark:border-indigo-800/30 mb-4">
            <FaRegLightbulb className="text-indigo-600 dark:text-indigo-400" />
            <span className="text-xs font-medium text-indigo-700 dark:text-indigo-300">Transparent enterprise pricing</span>
          </div>
          
          <h2 className="text-4xl md:text-5xl font-bold mb-4 text-gray-900 dark:text-white leading-tight">
            Enterprise-Grade Analytics<br/>
            <span className="bg-gradient-to-r from-blue-600 via-purple-600 to-emerald-600 bg-clip-text text-transparent">
              Without Enterprise Complexity
            </span>
          </h2>
          
          <p className="text-xl text-gray-600 dark:text-gray-300 mb-8 max-w-2xl mx-auto">
            Transform your business data into actionable insights with JaanchAI's powerful intelligence platform
          </p>
          
          {/* Billing period selector */}
          <div className="mb-8 bg-white dark:bg-gray-800 p-1.5 rounded-xl shadow-md inline-flex">
            {[
              { id: 'monthly', label: 'Monthly' },
              { id: 'quarterly', label: 'Quarterly' },
              { id: 'biannual', label: '6 Month' },
              { id: 'annual', label: 'Annual' }
            ].map((period) => (
              <button
                key={period.id}
                onClick={() => setBillingPeriod(period.id)}
                className={`px-5 py-2.5 rounded-lg text-sm font-medium transition-all ${
                  billingPeriod === period.id
                    ? 'bg-indigo-600 text-white shadow-md'
                    : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
                }`}
              >
                {period.label}
              </button>
            ))}
          </div>
          
          {/* Savings indicator */}
          <div className="max-w-md mx-auto mb-6">
            <div className="h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
              {billingPeriod !== 'monthly' && (
                <div 
                  className="h-full bg-emerald-500 dark:bg-emerald-400 transition-all duration-500 ease-out"
                  style={{ 
                    width: `${billingPeriod === 'quarterly' ? 8 : billingPeriod === 'biannual' ? 15 : 20}%` 
                  }}
                ></div>
              )}
            </div>
            <div className="flex justify-between mt-1 text-xs text-gray-500 dark:text-gray-400">
              <span>0% savings</span>
              <span>20% savings + reduced implementation fee</span>
            </div>
          </div>
          
          {/* Early adopter toggle */}
          <div className="flex items-center justify-center gap-3 mb-8">
            <span className="text-gray-600 dark:text-gray-400">Regular Pricing</span>
            <button 
              onClick={() => setShowEarlyAdopter(!showEarlyAdopter)}
              className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
                showEarlyAdopter ? 'bg-indigo-600' : 'bg-gray-300 dark:bg-gray-600'
              }`}
            >
              <span 
                className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                  showEarlyAdopter ? 'translate-x-6' : 'translate-x-1'
                }`} 
              />
            </button>
            <span className={`flex items-center gap-1 ${
              showEarlyAdopter ? 'text-indigo-600 dark:text-indigo-400 font-medium' : 'text-gray-600 dark:text-gray-400'
            }`}>
              <FaMedal className="text-sm" />
              Early Adopter Pricing
            </span>
          </div>
          
          {/* Early adopter info */}
          {showEarlyAdopter && (
            <div className="max-w-xl mx-auto bg-indigo-50 dark:bg-indigo-900/30 border border-indigo-200 dark:border-indigo-800/30 rounded-lg p-4 mb-8">
              <div className="flex items-center justify-center mb-2">
                <FaMedal className="text-indigo-600 dark:text-indigo-400 mr-2" />
                <h4 className="text-lg font-medium text-indigo-700 dark:text-indigo-300">Limited Time Offer</h4>
              </div>
              <p className="text-indigo-700 dark:text-indigo-300 mb-2">
                First 90 days pricing - Save 15% extra on annual plans and get implementation fee waived completely!
              </p>
              <div className="text-sm text-indigo-600/70 dark:text-indigo-400/70">
                Includes guaranteed price lock for 2 years and product advisory board seat
              </div>
            </div>
          )}
          
          {/* Compare all plans button */}
          <button 
            onClick={() => setShowComparisonModal(true)}
            className="text-indigo-600 dark:text-indigo-400 font-medium hover:text-indigo-700 dark:hover:text-indigo-300 flex items-center gap-1 mx-auto mb-12"
          >
            <FaInfoCircle className="text-sm" />
            Compare all plan features
          </button>
        </div>
        
        {/* Pricing cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {pricingTiers.map((tier, index) => {
            const Icon = tier.icon;
            return (
              <div 
                key={index} 
                className={`relative rounded-2xl transition-all transform hover:-translate-y-2 hover:shadow-xl duration-300 overflow-hidden border-2 ${
                  tier.isPopular 
                    ? `border-gray-400 dark:border-gray-600 shadow-lg` 
                    : 'border-gray-200 dark:border-gray-700'
                }`}
              >
                {/* Plan header */}
                <div className={`p-6 bg-to-br ${
                  tier.bgGradient
                } dark:${tier.darkBgGradient}`}>
                  {tier.isPopular && (
                    <div className="absolute top-0 right-0 bg-gray-500 text-white text-xs font-bold px-3 py-1 rounded-bl-lg">
                      MOST POPULAR
                    </div>
                  )}
                  
                  <div className="flex items-start justify-between mb-4">
                    <div>
                      <h3 className="text-2xl font-bold text-gray-900 dark:text-white">{tier.title}</h3>
                      <p className="text-sm text-gray-600 dark:text-gray-400">{tier.subtitle}</p>
                    </div>
                    <div className={`p-2 rounded-full bg-${tier.color}-100 dark:bg-${tier.color}-900/30 text-${tier.color}-600 dark:text-${tier.color}-400`}>
                      <Icon className="w-6 h-6" />
                    </div>
                  </div>
                  
                  <p className="text-gray-600 dark:text-gray-400 text-sm mb-4">{tier.description}</p>
                  
                  <div className="mb-4">
                    <span className="text-3xl font-bold text-gray-900 dark:text-white">
                      {showEarlyAdopter && billingPeriod === 'annual' 
                        ? tier.earlyAdopter.monthly
                        : tier.pricing[billingPeriod]}
                    </span>
                    <span className="text-gray-600 dark:text-gray-400 ml-1 text-sm">{getCommitmentLabel()}</span>
                  </div>
                  
                  {/* Implementation fee & savings */}
                  <div className="bg-white/70 dark:bg-gray-800/30 rounded-lg p-4 mb-4">
                    <div className="flex justify-between text-sm mb-2">
                      <span className="text-gray-600 dark:text-gray-400">Total commitment</span>
                      <span className="font-medium text-gray-900 dark:text-white">
                        {showEarlyAdopter && billingPeriod === 'annual'
                          ? getTotalCommitment(tier.earlyAdopter.monthly)
                          : getTotalCommitment(tier.pricing[billingPeriod])}
                      </span>
                    </div>
                    
                    <div className="flex justify-between text-sm mb-3">
                      <span className="text-gray-600 dark:text-gray-400">Implementation fee</span>
                      <span className={`font-medium ${
                        showEarlyAdopter && billingPeriod === 'annual'
                          ? 'text-emerald-600 dark:text-emerald-400'
                          : 'text-gray-900 dark:text-white'
                      }`}>
                        {getImplementationFee(tier)}
                      </span>
                    </div>
                    
                    {/* Savings visualization */}
                    {(getSavingsPercentage(tier) > 0 || (showEarlyAdopter && billingPeriod === 'annual')) && (
                      <>
                        <div className="h-1.5 w-full bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden mb-2">
                          <div 
                            className="h-full bg-emerald-500 dark:bg-emerald-400"
                            style={{ 
                              width: `${showEarlyAdopter && billingPeriod === 'annual' ? 35 : getSavingsPercentage(tier)}%` 
                            }}
                          ></div>
                        </div>
                        <div className="flex justify-between text-xs">
                          <span className="text-gray-500 dark:text-gray-400">Regular price</span>
                          <span className="text-emerald-600 dark:text-emerald-400 font-medium">
                            {showEarlyAdopter && billingPeriod === 'annual'
                              ? '35% total savings'
                              : tier.savings[billingPeriod] + ' discount'
                            }
                          </span>
                        </div>
                      </>
                    )}
                    
                    {/* Early adopter total savings */}
                    {showEarlyAdopter && billingPeriod === 'annual' && (
                      <div className="mt-3 pt-2 border-t border-gray-200 dark:border-gray-700 flex justify-between text-sm">
                        <span className="text-indigo-600 dark:text-indigo-400">Total savings</span>
                        <span className="font-bold text-indigo-600 dark:text-indigo-400">
                          {tier.earlyAdopter.savings}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                
                {/* Plan content */}
                <div className="bg-white dark:bg-gray-800 p-6">
                  {/* Top features highlight */}
                  <div className="mb-6">
                    <h4 className="font-medium text-gray-900 dark:text-white mb-3">Top Features</h4>
                    <ul className="space-y-3">
                      {tier.topFeatures.map((feature, i) => (
                        <li key={i} className="flex items-center">
                          <span className={`p-1 rounded-full bg-${tier.color}-100 dark:bg-${tier.color}-900/30 mr-3`}>
                            <FaCheck className={`w-3 h-3 text-${tier.color}-600 dark:text-${tier.color}-400`} />
                          </span>
                          <span className="text-gray-700 dark:text-gray-300 text-sm">{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  
                  {/* Period benefit */}
                  <div className="mb-6">
                    <div className="mb-2 flex items-center gap-1.5">
                      <FaRegClock className={`text-${tier.color}-600 dark:text-${tier.color}-400 text-sm`} />
                      <span className="text-sm font-medium text-gray-900 dark:text-white">
                        {billingPeriod.charAt(0).toUpperCase() + billingPeriod.slice(1)} plan benefit
                      </span>
                    </div>
                    <div className={`p-3 rounded-lg border ${tier.borderColor} dark:${tier.darkBorderColor} bg-${tier.color}-50/50 dark:bg-${tier.color}-900/10`}>
                      <p className="text-sm text-gray-700 dark:text-gray-300">{tier.additionalBenefits[billingPeriod]}</p>
                    </div>
                  </div>
                  
                  {/* All features expandable */}
                  <div className="border-t border-gray-200 dark:border-gray-700 pt-5 mb-6">
                    <button 
                      onClick={() => setShowComparisonModal(true)}
                      className="text-sm flex items-center gap-1.5 text-gray-600 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-indigo-400"
                    >
                      <FaInfoCircle className="text-xs" />
                      <span>See all {tier.allFeatures.length} features</span>
                    </button>
                  </div>
                  
                  {/* CTA button */}
                  <button className={`w-full py-3 px-4 rounded-lg font-medium transition-colors duration-300 ${
                    tier.isPopular 
                      ? tier.buttonBg + ' text-white shadow-md' 
                      : tier.buttonLight
                  }`}>
                    Select {tier.title} Plan
                  </button>
                  
                  {/* Contact sales link */}
                  <div className="mt-3 text-center">
                  <button className="text-sm text-gray-500 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-indigo-400 inline-flex items-center gap-1 bg-transparent border-0 p-0 cursor-pointer">
                    <FaQuestionCircle className="text-xs" />
                    <span>Talk to sales</span>
                  </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        
        {/* ROI Calculator */}
        <div className="mt-16 max-w-4xl mx-auto text-center mb-12">
          <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-3">Calculate Your Potential ROI</h3>
          <p className="text-gray-600 dark:text-gray-400 mb-6 max-w-2xl mx-auto">
            See how much time and money JaanchAI can save your organization by streamlining your data analysis.
          </p>
          
          {showCalculator ? (
            <ROICalculator />
          ) : (
            <button 
              onClick={() => setShowCalculator(true)}
              className="px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
            >
              Open ROI Calculator
            </button>
          )}
        </div>
        
        {/* FAQ Section */}
        <div className="mt-16 max-w-3xl mx-auto">
          <h3 className="text-2xl font-bold text-gray-900 dark:text-white text-center mb-8">Frequently Asked Questions</h3>
          
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div 
                key={index} 
                className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700 overflow-hidden"
              >
                <button 
                  onClick={() => setActiveFaq(activeFaq === index ? null : index)}
                  className="flex items-center justify-between w-full p-5 text-left"
                >
                  <span className="font-medium text-gray-900 dark:text-white">{faq.question}</span>
                  <span className={`transition-transform duration-200 ${activeFaq === index ? 'rotate-180' : ''}`}>
                    <IoChevronForwardOutline className="w-5 h-5 text-gray-500 dark:text-gray-400 transform rotate-90" />
                  </span>
                </button>
                
                {activeFaq === index && (
                  <div className="px-5 pb-5">
                    <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
                      <p className="text-gray-600 dark:text-gray-300 text-sm">{faq.answer}</p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
     
     
      </div>
      
      {/* Comparison modal */}
      {showComparisonModal && <FeatureComparisonModal />}
    </section>
  );
};

export default Pricing;