import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/features/userSlice';
import { authService } from "../../utils/axios";
import { AlertCircle, Eye, EyeOff, Mail, Lock, ChevronRight } from 'lucide-react';
import { motion } from 'framer-motion';
import JaanchLogo from '../JaanchLogo';

const LoginPage = () => {
  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const setCookie = (name, value, days = 7) => {
    const expires = new Date(Date.now() + days * 864e5).toUTCString();
    document.cookie = `${name}=${value}; expires=${expires}; path=/; secure; samesite=strict`;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const data = await authService.login({
        email: credentials.email,
        password: credentials.password
      });
      setCookie('access_token', data.access_token);
      dispatch(setUser({user: data.user, organization: data.organization}));
      navigate('/dashboard');
    } catch (error) {
      setError(error.detail || 'Invalid email or password. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen w-full bg-gray-50 dark:bg-gray-900 flex">
      {/* Left panel - Brand/Logo area */}
      <div className="hidden lg:flex lg:w-1/2 bg-indigo-600 flex-col justify-center items-center relative overflow-hidden">
        {/* Rich background gradient with subtle blue-purple gradient */}
        <div className="absolute inset-0 bg-gradient-to-br from-indigo-600 via-indigo-700 to-purple-800" />
        
        {/* Refined hexagonal grid with better opacity */}
        <div className="absolute inset-0 opacity-15">
          <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <pattern id="hexagonPattern" width="60" height="52" patternUnits="userSpaceOnUse" patternTransform="scale(2.5) rotate(5)">
                <path d="M30,0 L60,17.3 L60,34.7 L30,52 L0,34.7 L0,17.3 Z" fill="none" stroke="rgba(255,255,255,0.25)" strokeWidth="0.8" />
              </pattern>
            </defs>
            <rect width="100%" height="100%" fill="url(#hexagonPattern)" />
          </svg>
        </div>
        
        {/* Subtle glowing orbs with reduced quantity and intensity */}
        <div className="absolute w-full h-full">
          {[...Array(10)].map((_, i) => (
            <motion.div
              key={i}
              initial={{ 
                x: Math.random() * 100 - 50,
                y: Math.random() * 100 - 50,
                opacity: Math.random() * 0.3 + 0.1
              }}
              animate={{ 
                x: Math.random() * 100 - 50,
                y: Math.random() * 100 - 50,
                opacity: Math.random() * 0.3 + 0.1
              }}
              transition={{ 
                duration: Math.random() * 15 + 25,
                repeat: Infinity,
                repeatType: "reverse"
              }}
              className="absolute top-1/2 left-1/2 rounded-full blur-lg"
              style={{
                width: `${Math.random() * 60 + 20}px`,
                height: `${Math.random() * 60 + 20}px`,
                background: `rgba(255, 255, 255, ${Math.random() * 0.15 + 0.05})`,
                boxShadow: `0 0 ${Math.random() * 30 + 10}px rgba(255, 255, 255, 0.2)`,
                marginTop: `-${Math.random() * 400}px`,
                marginLeft: `-${Math.random() * 400}px`,
              }}
            />
          ))}
        </div>
        
        {/* Refined animated connection lines */}
        <svg className="absolute inset-0 w-full h-full opacity-15" xmlns="http://www.w3.org/2000/svg">
          {[...Array(5)].map((_, i) => {
            const x1 = Math.random() * 100;
            const y1 = Math.random() * 100;
            const x2 = Math.random() * 100;
            const y2 = Math.random() * 100;
            
            return (
              <motion.line
                key={i}
                x1={`${x1}%`}
                y1={`${y1}%`}
                x2={`${x2}%`}
                y2={`${y2}%`}
                stroke="rgba(255,255,255,0.4)"
                strokeWidth="0.4"
                initial={{ pathLength: 0, opacity: 0 }}
                animate={{ 
                  pathLength: 1, 
                  opacity: [0, 0.2, 0.3, 0.2, 0]
                }}
                transition={{ 
                  duration: Math.random() * 15 + 20,
                  repeat: Infinity,
                  repeatType: "loop",
                  ease: "linear"
                }}
              />
            );
          })}
        </svg>
        
        {/* Enhanced branding content with better logo presentation */}
        <div className="relative z-10 max-w-md p-12 text-center">
          {/* Logo with backdrop effect */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="mb-12"
          >
            <div className="w-32 h-32 mx-auto bg-white/10 rounded-full flex items-center justify-center backdrop-blur-md border border-white/20 shadow-2xl">
              <JaanchLogo className="w-20 h-20 text-white" />
            </div>
          </motion.div>
          
          {/* Tagline with subtle animation */}
          <motion.p 
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
            className="text-white/95 text-xl font-light leading-relaxed tracking-wide mb-12"
          >
            Intelligent analytics platform for modern enterprises
          </motion.p>
          
          {/* Refined feature highlights */}
          <div className="grid grid-cols-1 gap-5 mt-10">
            {[
              { title: "Real-time Analysis", description: "Process data streams instantly" },
              { title: "AI Predictions", description: "Forecast trends with machine learning" },
              { title: "Secure Platform", description: "Enterprise-grade security standards" }
            ].map((feature, index) => (
              <motion.div 
                key={index}
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.5 + (index * 0.15) }}
                className="flex items-center bg-white/8 hover:bg-white/12 backdrop-blur-sm rounded-xl px-5 py-4 border border-white/10 transition-all duration-300 group cursor-default"
              >
                <div className="w-1.5 h-1.5 rounded-full bg-white/80 mr-3 group-hover:bg-white transition-all duration-300"></div>
                <div className="text-left flex-1">
                  <h3 className="font-medium text-white text-base">{feature.title}</h3>
                  <p className="text-white/70 text-sm mt-1">{feature.description}</p>
                </div>
                <ChevronRight className="w-4 h-4 text-white/40 group-hover:text-white/70 opacity-0 group-hover:opacity-100 transition-all duration-300 transform translate-x-0 group-hover:translate-x-1" />
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Right panel - Login form with enhanced styling */}
      <div className="w-full lg:w-1/2 flex items-center justify-center p-6 md:p-12 bg-gray-50 dark:bg-gray-900">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
          className="w-full max-w-md"
        >
          {/* Mobile logo - visible only on smaller screens */}
          <div className="lg:hidden text-center mb-10">
            <div className="flex justify-center mb-4">
              <JaanchLogo className="w-16 h-16 text-indigo-600 dark:text-indigo-400" />
            </div>
            <p className="text-gray-600 dark:text-gray-400 mt-2">Sign in to continue</p>
          </div>

          {/* Enhanced login form container */}
          <motion.div 
            className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 border border-gray-100 dark:border-gray-700"
            initial={{ y: 20 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <div className="mb-8">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Welcome back</h2>
              <p className="text-gray-600 dark:text-gray-400 mt-1.5">Please enter your credentials to sign in</p>
            </div>

            {error && (
              <motion.div 
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                className="mb-6 p-4 rounded-lg bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800/30"
              >
                <div className="flex items-center">
                  <AlertCircle className="h-5 w-5 text-red-500 dark:text-red-400 mr-3 flex-shrink-0" />
                  <p className="text-sm text-red-600 dark:text-red-400">{error}</p>
                </div>
              </motion.div>
            )}

            <form onSubmit={handleLogin} className="space-y-6">
              <div className="space-y-1.5">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Email address
                </label>
                <div className="relative group">
                  <div className="absolute inset-y-0 left-0 pl-3.5 flex items-center pointer-events-none">
                    <Mail className="h-5 w-5 text-gray-400 group-focus-within:text-indigo-500 dark:group-focus-within:text-indigo-400 transition-colors duration-200" />
                  </div>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    value={credentials.email}
                    onChange={handleChange}
                    disabled={isLoading}
                    className="block w-full pl-11 pr-3.5 py-3.5 border border-gray-300 dark:border-gray-600 rounded-xl bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-400/80 focus:outline-none focus:ring-2 focus:ring-indigo-500/50 dark:focus:ring-indigo-400/50 focus:border-indigo-500 dark:focus:border-indigo-400 disabled:opacity-50 transition-all duration-200"
                    placeholder="you@example.com"
                  />
                </div>
              </div>

              <div className="space-y-1.5">
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Password
                  </label>
                  <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-300 transition-colors duration-150">
                    Forgot password?
                  </a>
                </div>
                <div className="relative group">
                  <div className="absolute inset-y-0 left-0 pl-3.5 flex items-center pointer-events-none">
                    <Lock className="h-5 w-5 text-gray-400 group-focus-within:text-indigo-500 dark:group-focus-within:text-indigo-400 transition-colors duration-200" />
                  </div>
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    required
                    value={credentials.password}
                    onChange={handleChange}
                    disabled={isLoading}
                    className="block w-full pl-11 pr-11 py-3.5 border border-gray-300 dark:border-gray-600 rounded-xl bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-400/80 focus:outline-none focus:ring-2 focus:ring-indigo-500/50 dark:focus:ring-indigo-400/50 focus:border-indigo-500 dark:focus:border-indigo-400 disabled:opacity-50 transition-all duration-200"
                    placeholder="••••••••"
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 pr-3.5 flex items-center"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <EyeOff className="h-5 w-5 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-colors duration-150" />
                    ) : (
                      <Eye className="h-5 w-5 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-colors duration-150" />
                    )}
                  </button>
                </div>
              </div>

              <div className="pt-2">
                <motion.button
                  type="submit"
                  disabled={isLoading}
                  className="w-full flex justify-center py-3.5 px-4 border border-transparent rounded-xl shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800 disabled:opacity-50 transition-all duration-200"
                  whileHover={{ scale: 1.01 }}
                  whileTap={{ scale: 0.98 }}
                >
                  {isLoading ? 'Signing in...' : 'Sign in'}
                </motion.button>
              </div>
            </form>

            <div className="mt-8 pt-6 border-t border-gray-200 dark:border-gray-700">
              <p className="text-center text-sm text-gray-600 dark:text-gray-400">
                Don't have an account?{' '}
                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-300 transition-colors duration-150">
                  Create an account
                </a>
              </p>
            </div>
          </motion.div>
          
          {/* Footer text */}
          <p className="text-center text-xs text-gray-500 dark:text-gray-600 mt-8">
            © {new Date().getFullYear()} JaanchAI. All rights reserved.
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default LoginPage;