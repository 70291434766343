import React, { useState } from 'react';
import { 
  FaIndustry, 
  FaShoppingBag, 
  FaTruck, 
  FaBuilding, 
  FaChartLine, 
  FaChartBar, 
  FaQuestionCircle,
  FaLayerGroup,
  FaRocket,
  FaCheck,
  FaArrowRight,
  FaLightbulb,
  FaInfoCircle,
  FaClock
} from 'react-icons/fa';
import { IoChevronForwardOutline, IoClose } from 'react-icons/io5';

const Solutions = () => {
  const [activeIndustry, setActiveIndustry] = useState('manufacturing');
  const [showCalculator, setShowCalculator] = useState(false);
  const [showComparison, setShowComparison] = useState(false);
  const [activeFaq, setActiveFaq] = useState(null);
  const [hoverIndustry, setHoverIndustry] = useState(null);

  // Industry data
  const industries = [
    { 
      id: 'manufacturing', 
      title: 'Manufacturing',
      subtitle: 'Unified Production Intelligence',
      description: 'Connect your production systems and optimize your manufacturing processes with real-time analytics and predictive maintenance.',
      icon: FaIndustry,
      useCases: [
        {
          title: 'Production Efficiency Optimization',
          description: 'Connect your production data to identify bottlenecks and improvement opportunities across multiple facilities.',
          stats: [
            { value: '15-20%', label: 'reduction in changeover times' },
            { value: '8-12%', label: 'reduction in raw material inventory' },
            { value: '30-40%', label: 'faster quality issue identification' }
          ]
        },
        {
          title: 'Inventory & Supply Chain Intelligence',
          description: 'Optimize inventory levels and reduce working capital while ensuring production continuity.',
          stats: [
            { value: '12-18%', label: 'reduction in stock levels' },
            { value: '25%', label: 'improvement in supplier delivery compliance' },
            { value: '30-40%', label: 'decrease in stockout incidents' }
          ]
        },
        {
          title: 'Quality Control Analytics',
          description: 'Identify factors contributing to quality issues and scrap rates through cross-system data analysis.',
          stats: [
            { value: '15-25%', label: 'reduction in defect rates' },
            { value: '60%', label: 'faster root cause identification' },
            { value: '18%', label: 'lower quality control costs' }
          ]
        }
      ],
      kpis: [
        'OEE (Overall Equipment Effectiveness)',
        'Cycle Time & Throughput',
        'First Pass Yield',
        'Inventory Turnover',
        'On-Time Delivery',
        'Energy Usage Per Unit'
      ],
      features: [
        'Real-time production monitoring dashboards',
        'Predictive maintenance alerts',
        'Quality control analysis',
        'Supply chain optimization',
        'OEE tracking and analysis',
        'Production scheduling optimization'
      ]
    },
    { 
      id: 'ecommerce', 
      title: 'E-commerce',
      subtitle: 'Customer & Inventory Intelligence',
      description: 'Optimize your customer journey, inventory management, and marketing spend with a unified view of your e-commerce operations.',
      icon: FaShoppingBag,
      useCases: [
        {
          title: 'Customer Segmentation & Lifetime Value',
          description: 'Identify high-value customer segments and optimize acquisition and retention strategies.',
          stats: [
            { value: '15-20%', label: 'increase in customer retention' },
            { value: '25%', label: 'boost in repeat purchase rate' },
            { value: '30%', label: 'improvement in marketing ROI' }
          ]
        },
        {
          title: 'Inventory & Assortment Optimization',
          description: 'Predict demand patterns to minimize stockouts and overstock situations.',
          stats: [
            { value: '18-22%', label: 'reduction in inventory holding costs' },
            { value: '35%', label: 'decrease in stockouts' },
            { value: '25%', label: 'optimization of warehouse space' }
          ]
        },
        {
          title: 'Marketing Channel Effectiveness',
          description: 'Unified attribution modeling across marketing channels for optimized spend allocation.',
          stats: [
            { value: '20-30%', label: 'improvement in ROAS' },
            { value: '15%', label: 'reduction in customer acquisition costs' },
            { value: '25%', label: 'increase in conversion rates' }
          ]
        }
      ],
      kpis: [
        'Customer Acquisition Cost (CAC)',
        'Customer Lifetime Value (CLV)',
        'Conversion Rate',
        'Average Order Value (AOV)',
        'Return Rate',
        'Inventory Turnover'
      ],
      features: [
        'Customer segmentation and analysis',
        'Demand forecasting and planning',
        'Marketing attribution modeling',
        'Inventory optimization',
        'Product recommendation engine',
        'Dynamic pricing optimization'
      ]
    },
    { 
      id: 'logistics', 
      title: 'Logistics',
      subtitle: 'Route & Resource Optimization',
      description: 'Streamline your logistics operations with route optimization, warehouse management, and transportation analytics.',
      icon: FaTruck,
      useCases: [
        {
          title: 'Fleet & Route Optimization',
          description: 'Analyze delivery patterns to optimize routes, vehicle utilization, and driver allocation.',
          stats: [
            { value: '12-18%', label: 'reduction in fuel costs' },
            { value: '15-25%', label: 'increase in vehicle utilization' },
            { value: '30%', label: 'improvement in on-time delivery' }
          ]
        },
        {
          title: 'Warehouse Management',
          description: 'Optimize warehouse layouts and picking efficiency based on order patterns and item relationships.',
          stats: [
            { value: '20-30%', label: 'increase in warehouse productivity' },
            { value: '35%', label: 'reduction in picking errors' },
            { value: '15%', label: 'optimization of storage space' }
          ]
        },
        {
          title: 'Last-Mile Delivery Analytics',
          description: 'Calculate accurate delivery windows and optimize delivery staff allocation based on historical patterns.',
          stats: [
            { value: '25-35%', label: 'reduction in delivery exceptions' },
            { value: '40%', label: 'improvement in customer satisfaction' },
            { value: '15%', label: 'decrease in delivery cost per package' }
          ]
        }
      ],
      kpis: [
        'Cost Per Mile/Kilometer',
        'On-Time Delivery Rate',
        'Vehicle Utilization',
        'Warehouse Throughput',
        'Order Accuracy',
        'Fuel Efficiency'
      ],
      features: [
        'Route optimization and planning',
        'Fleet management analytics',
        'Warehouse optimization',
        'Delivery time prediction',
        'Transportation cost analysis',
        'Last-mile delivery optimization'
      ]
    },
    { 
      id: 'hospitality', 
      title: 'Hotels & Hospitality',
      subtitle: 'Revenue & Guest Experience Optimization',
      description: 'Maximize your revenue and enhance guest experiences with data-driven insights into pricing, operations, and customer preferences.',
      icon: FaBuilding,
      useCases: [
        {
          title: 'Revenue Management',
          description: 'Optimize room rates based on demand patterns, events, competitor pricing, and historical data.',
          stats: [
            { value: '10-15%', label: 'increase in RevPAR' },
            { value: '20%', label: 'optimization of occupancy rates' },
            { value: '35%', label: 'improvement in forecasting accuracy' }
          ]
        },
        {
          title: 'Operational Efficiency',
          description: 'Optimize staff scheduling and department performance against benchmarks and demand forecasts.',
          stats: [
            { value: '8-12%', label: 'reduction in labor costs' },
            { value: '20%', label: 'improvement in staff productivity' },
            { value: '15%', label: 'decrease in operational expenses' }
          ]
        },
        {
          title: 'Guest Experience Analytics',
          description: 'Analyze guest feedback across channels to identify factors driving satisfaction and repeat visits.',
          stats: [
            { value: '25%', label: 'increase in guest satisfaction scores' },
            { value: '30%', label: 'boost in repeat booking rates' },
            { value: '40%', label: 'reduction in negative reviews' }
          ]
        }
      ],
      kpis: [
        'RevPAR (Revenue Per Available Room)',
        'ADR (Average Daily Rate)',
        'Occupancy Rate',
        'Guest Satisfaction Score',
        'Staff Productivity',
        'F&B Cost Percentage'
      ],
      features: [
        'Revenue management and pricing',
        'Demand forecasting',
        'Staff scheduling optimization',
        'Guest satisfaction analysis',
        'Competitive pricing analysis',
        'Food & beverage optimization'
      ]
    }
  ];

  // Get current industry
  const currentIndustry = industries.find(ind => ind.id === activeIndustry) || industries[0];

  // FAQs data
  const faqs = [
    {
      question: "How long does implementation typically take?",
      answer: `For ${currentIndustry.title.toLowerCase()} solutions, typical implementation timelines range from 2-4 weeks for basic setup to 8-12 weeks for enterprise-scale deployments with custom integrations. Our implementation team works closely with your IT department to ensure a smooth transition with minimal disruption to your operations.`
    },
    {
      question: "Can I integrate with my existing systems?",
      answer: `Yes, JaanchAI is designed to work with your existing ${currentIndustry.title.toLowerCase()} systems and data sources. We have pre-built connectors for all major ERP, CRM, and ${currentIndustry.id}-specific platforms. Our implementation process includes mapping your current data architecture and establishing the necessary connections to provide a unified analytics environment.`
    },
    {
      question: "What kind of support is included?",
      answer: "All plans include dedicated implementation support, regular check-ins, and access to our customer success team. Professional plans include standard business hours support, while Business and Enterprise plans include priority support with faster response times and dedicated success managers for strategic guidance."
    },
    {
      question: "Do you offer custom solutions?",
      answer: `Absolutely. While our platform includes pre-built analytics specific to ${currentIndustry.title.toLowerCase()}, we understand that each business has unique requirements. Our Enterprise plan includes custom modeling, specialized dashboards, and tailored insights for your specific business challenges.`
    }
  ];

  // Implementation steps
  const implementationSteps = [
    {
      title: "Discovery",
      description: `We analyze your ${currentIndustry.title.toLowerCase()} data landscape and identify business objectives`,
      duration: "1-2 weeks"
    },
    {
      title: "Integration",
      description: "Connect your data sources and establish initial dashboards",
      duration: "2-3 weeks"
    },
    {
      title: "Validation",
      description: "Verify insights accuracy and refine analytics models",
      duration: "1 week"
    },
    {
      title: "Expansion",
      description: "Roll out across teams and add advanced capabilities",
      duration: "Ongoing"
    }
  ];

  // ROI Calculator component
  const ROICalculator = () => {
    const [dataVolume, setDataVolume] = useState(1);
    const [employees, setEmployees] = useState(50);
    const [analysts, setAnalysts] = useState(2);
    
    // Calculate savings based on industry
    const calculateSavings = () => {
      let baseSavings;
      
      switch(currentIndustry.id) {
        case 'manufacturing':
          baseSavings = dataVolume * 250000 + employees * 2000 + analysts * 25000;
          break;
        case 'ecommerce':
          baseSavings = dataVolume * 200000 + employees * 1500 + analysts * 30000;
          break;
        case 'logistics':
          baseSavings = dataVolume * 220000 + employees * 1800 + analysts * 28000;
          break;
        case 'hospitality':
          baseSavings = dataVolume * 180000 + employees * 1600 + analysts * 22000;
          break;
        default:
          baseSavings = dataVolume * 200000 + employees * 1500 + analysts * 25000;
      }
      
      // Apply efficiency factor (assumed 70% of theoretical maximum)
      return baseSavings * 0.7;
    };
    
    const annualSavings = calculateSavings();
    const implementationCost = 125000; // Assumed Business tier implementation fee
    const annualSubscription = 36000 * 12; // Assumed Business tier annual subscription
    const totalCost = implementationCost + annualSubscription;
    const roi = ((annualSavings - totalCost) / totalCost * 100).toFixed(0);
    const paybackMonths = Math.ceil((totalCost / annualSavings) * 12);
    
    return (
      <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-gray-900 dark:text-white">
            {currentIndustry.title} ROI Calculator
          </h3>
          <button onClick={() => setShowCalculator(false)} className="text-gray-400 hover:text-gray-500">
            <IoClose className="w-5 h-5" />
          </button>
        </div>
        
        <div className="space-y-4 mb-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Data volume (in millions of records)
            </label>
            <input 
              type="range" 
              min="0.1" 
              max="5" 
              step="0.1"
              value={dataVolume} 
              onChange={(e) => setDataVolume(parseFloat(e.target.value))}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
            />
            <div className="flex justify-between text-sm text-gray-500 mt-1">
              <span>0.1M</span>
              <span>{dataVolume.toFixed(1)}M records</span>
              <span>5M+</span>
            </div>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Employees using data insights
            </label>
            <input 
              type="range" 
              min="10" 
              max="500" 
              value={employees} 
              onChange={(e) => setEmployees(parseInt(e.target.value))}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
            />
            <div className="flex justify-between text-sm text-gray-500 mt-1">
              <span>10</span>
              <span>{employees} employees</span>
              <span>500</span>
            </div>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Current data analysts/scientists
            </label>
            <input 
              type="range" 
              min="0" 
              max="10" 
              value={analysts} 
              onChange={(e) => setAnalysts(parseInt(e.target.value))}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
            />
            <div className="flex justify-between text-sm text-gray-500 mt-1">
              <span>0</span>
              <span>{analysts} analysts</span>
              <span>10+</span>
            </div>
          </div>
        </div>
        
        <div className="bg-gray-50 dark:bg-gray-700/30 rounded-lg p-4">
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <div className="text-sm text-gray-500 dark:text-gray-400 mb-1">Annual cost savings</div>
              <div className="text-2xl font-bold text-gray-900 dark:text-white">₹{annualSavings.toLocaleString('en-IN')}</div>
            </div>
            <div>
              <div className="text-sm text-gray-500 dark:text-gray-400 mb-1">Payback period</div>
              <div className="text-2xl font-bold text-emerald-600 dark:text-emerald-400">{paybackMonths} months</div>
            </div>
          </div>
          
          <div className="flex items-center justify-between p-3 bg-indigo-900/20 rounded-lg border border-indigo-800/50">
            <div className="text-sm font-medium text-indigo-300">3-Year ROI</div>
            <div className="text-xl font-bold text-indigo-300">{roi}%</div>
          </div>
          
          <div className="mt-4 text-sm text-gray-500 dark:text-gray-400">
            Based on industry averages for {currentIndustry.title.toLowerCase()} operations. Request a detailed analysis for your specific business scenario.
          </div>
        </div>
        
        <div className="mt-6 flex justify-center">
          <button 
            className={`px-4 py-2 ${currentIndustry.buttonBg} text-white rounded-lg transition-colors`}
          >
            Get Personalized ROI Analysis
          </button>
        </div>
      </div>
    );
  };

  // Comparison Modal
  const FeatureComparisonModal = () => {
    // Solution tiers for the current industry
    const solutionTiers = [
      {
        title: 'Professional',
        price: '₹25,000/month'
      },
      {
        title: 'Business',
        price: '₹45,000/month',
        popular: true
      },
      {
        title: 'Enterprise',
        price: 'Custom'
      }
    ];
    
    // Feature categories and comparison
    const featureCategories = [
      {
        category: `${currentIndustry.title} Analytics`,
        features: [
          { 
            name: `${currentIndustry.kpis[0]} tracking`, 
            professional: "Basic", 
            business: "Advanced", 
            enterprise: "Enterprise-grade" 
          },
          { 
            name: `${currentIndustry.kpis[1]} analysis`, 
            professional: "✓", 
            business: "✓", 
            enterprise: "✓" 
          },
          { 
            name: `${currentIndustry.kpis[2]} monitoring`, 
            professional: "Limited", 
            business: "✓", 
            enterprise: "✓" 
          }
        ]
      },
      {
        category: "Data Capabilities",
        features: [
          { name: "Data sources", professional: "5", business: "Unlimited", enterprise: "Unlimited" },
          { name: "Historical data retention", professional: "12 months", business: "36 months", enterprise: "Unlimited" },
          { name: "Data refresh frequency", professional: "Daily", business: "Hourly", enterprise: "Real-time" }
        ]
      },
      {
        category: "Advanced Features",
        features: [
          { name: "Predictive analytics", professional: "Basic", business: "Advanced", enterprise: "Custom models" },
          { name: `${currentIndustry.useCases[0].title}`, professional: "✓", business: "✓", enterprise: "✓" },
          { name: `${currentIndustry.useCases[1].title}`, professional: "Limited", business: "✓", enterprise: "✓" },
          { name: `${currentIndustry.useCases[2].title}`, professional: "—", business: "✓", enterprise: "✓" }
        ]
      }
    ];
    
    return (
      <div className="fixed inset-0 bg-black/50 dark:bg-black/70 flex items-center justify-center z-50 p-4">
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-2xl max-w-5xl w-full max-h-[90vh] overflow-hidden flex flex-col">
          <div className="flex items-center justify-between px-6 py-4 border-b border-gray-200 dark:border-gray-700">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              {currentIndustry.title} Solutions Comparison
            </h3>
            <button 
              onClick={() => setShowComparison(false)}
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            >
              <IoClose className="w-6 h-6" />
            </button>
          </div>
          
          <div className="overflow-auto p-6">
            <div className="grid grid-cols-4 gap-4">
              <div className="sticky top-0 bg-white dark:bg-gray-800 z-10 pt-10">
                <div className="font-medium text-gray-500 dark:text-gray-400">Features</div>
              </div>
              
              {solutionTiers.map((tier, index) => (
                <div key={index} className="sticky top-0 bg-white dark:bg-gray-800 z-10">
                  <div className={`rounded-lg p-4 ${
                    tier.popular 
                      ? 'bg-purple-50 dark:bg-purple-900/30 border border-purple-200 dark:border-purple-800/50' 
                      : 'bg-gray-50 dark:bg-gray-700/30 border border-gray-200 dark:border-gray-700'
                  }`}>
                    <div className="font-medium text-gray-900 dark:text-white">{tier.title}</div>
                    <div className="text-lg font-bold text-gray-900 dark:text-white">
                      {tier.price}
                    </div>
                  </div>
                </div>
              ))}
              
              {featureCategories.map((category, catIndex) => (
                <React.Fragment key={catIndex}>
                  <div className="col-span-4 mt-6 mb-2">
                    <h4 className="text-lg font-medium text-gray-900 dark:text-white">{category.category}</h4>
                    <div className="h-0.5 w-full bg-gray-100 dark:bg-gray-700 mt-2"></div>
                  </div>
                  
                  {category.features.map((feature, featureIndex) => (
                    <React.Fragment key={featureIndex}>
                      <div className="py-3 text-sm text-gray-800 dark:text-gray-200">{feature.name}</div>
                      
                      <div className="py-3 text-center">
                                                  <span className={`text-sm ${
                          feature.professional === "—" 
                            ? "text-gray-500" 
                            : "text-gray-200 font-medium"
                        }`}>
                          {feature.professional}
                        </span>
                      </div>
                      
                      <div className="py-3 text-center">
                                                  <span className={`text-sm ${
                          feature.business === "—" 
                            ? "text-gray-500" 
                            : "text-purple-400 font-medium"
                        }`}>
                          {feature.business}
                        </span>
                      </div>
                      
                      <div className="py-3 text-center">
                                                  <span className={`text-sm ${
                          feature.enterprise === "—" 
                            ? "text-gray-500" 
                            : "text-emerald-400 font-medium"
                        }`}>
                          {feature.enterprise}
                        </span>
                      </div>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </div>
          </div>
          
          <div className="border-t border-gray-200 dark:border-gray-700 p-4 bg-gray-50 dark:bg-gray-800">
            <div className="flex justify-end">
              <button 
                onClick={() => setShowComparison(false)}
                className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };



  return (
    <section className="bg-gray-900 py-20">
      <div className="container mx-auto px-4">
        {/* Header section */}
        <div className="text-center max-w-3xl mx-auto mb-16">
          <div className="inline-flex items-center gap-2 px-3 py-1 rounded-full bg-indigo-900/30 border border-indigo-800/30 mb-4">
            <FaLightbulb className="text-indigo-400" />
            <span className="text-xs font-medium text-indigo-300">Industry-specific solutions</span>
          </div>
          
          <h2 className="text-4xl md:text-5xl font-bold mb-4 text-white leading-tight">
            Turn Business Data Into<br/>
            <span className="bg-gradient-to-r from-blue-600 via-purple-600 to-emerald-600 bg-clip-text text-transparent">
              Actionable Intelligence
            </span>
          </h2>
          
          <p className="text-xl text-gray-300 mb-8">
            JaanchAI streamlines your scattered data into insights without the complexity of traditional analytics
          </p>
        </div>
        
        {/* Industry selector */}
        <div className="max-w-4xl mx-auto bg-gray-800 rounded-xl shadow-md p-1.5 mb-12">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-1.5">
            {industries.map((industry) => {
              const Icon = industry.icon;
              return (
                <button
                  key={industry.id}
                  onClick={() => setActiveIndustry(industry.id)}
                  onMouseEnter={() => setHoverIndustry(industry.id)}
                  onMouseLeave={() => setHoverIndustry(null)}
                  className={`relative px-4 py-3 rounded-lg text-sm font-medium transition-all ${
                    activeIndustry === industry.id
                      ? 'bg-indigo-600 text-white shadow-md'
                      : 'text-gray-300 hover:bg-gray-700'
                  }`}
                >
                  <div className="flex items-center justify-center md:justify-start gap-2">
                    <Icon className={`w-5 h-5 ${
                      activeIndustry === industry.id ? 'text-white' : 'text-indigo-400'
                    }`} />
                    <span className="hidden md:inline">{industry.title}</span>
                  </div>
                  
                  {(activeIndustry === industry.id || hoverIndustry === industry.id) && (
                    <div className="absolute inset-x-0 -bottom-10 flex justify-center">
                      <div className={`hidden md:block h-5 w-5 bg-indigo-600 transform rotate-45 ${
                        activeIndustry === industry.id ? 'opacity-100' : 'opacity-0'
                      } transition-opacity`}></div>
                    </div>
                  )}
                </button>
              );
            })}
          </div>
        </div>
        
        {/* Industry Content */}
        <div className="mt-16 grid grid-cols-1 lg:grid-cols-12 gap-8">
          {/* Left column - Main content */}
          <div className="lg:col-span-8">
            <div className="transition-all duration-500">
              {/* Header */}
              <div className="flex items-start gap-5 mb-8">
                <div className="p-3 rounded-lg bg-gray-700 flex-shrink-0">
                  {React.createElement(currentIndustry.icon, {
                    className: "w-8 h-8 text-indigo-400"
                  })}
                </div>
                <div>
                  <h2 className="text-3xl font-bold text-indigo-300 mb-1">
                    {currentIndustry.title} Solutions
                  </h2>
                  <p className="text-gray-400 text-lg">{currentIndustry.subtitle}</p>
                  <p className="text-gray-400 mt-2">{currentIndustry.description}</p>
                </div>
              </div>
              
              {/* Use Cases */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
                {currentIndustry.useCases.map((useCase, index) => (
                  <div 
                    key={index} 
                    className="bg-gray-800 p-6 rounded-xl border border-gray-700 transition-all hover:shadow-md"
                  >
                    <h3 className="text-lg font-semibold text-white mb-3">{useCase.title}</h3>
                    <p className="text-gray-400 text-sm mb-4">{useCase.description}</p>
                    <div className="space-y-3">
                      {useCase.stats.map((stat, i) => (
                        <div key={i} className="flex items-baseline gap-2">
                          <span className="text-indigo-300 font-bold">{stat.value}</span>
                          <span className="text-gray-400 text-sm">{stat.label}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              
              {/* KPI Section */}
              <div className="mb-12">
                <div className="flex items-center gap-2 mb-4">
                  <FaChartLine className="text-indigo-400" />
                  <h3 className="text-xl font-semibold text-white">Key Performance Indicators</h3>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                  {currentIndustry.kpis.map((kpi, index) => (
                    <div 
                      key={index} 
                      className="p-4 bg-gray-800 rounded-lg border border-gray-700 hover:shadow-md transition-shadow"
                    >
                      <div className="flex items-center gap-2">
                        <div className="w-2 h-2 rounded-full bg-indigo-400"></div>
                        <span className="text-gray-200">{kpi}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              
              {/* Implementation Journey */}
              <div className="mb-12">
                <div className="flex items-center gap-2 mb-6">
                  <FaRocket className="text-indigo-400" />
                  <h3 className="text-xl font-semibold text-white">Implementation Journey</h3>
                </div>
                <div className="relative">
                  {/* Progress line */}
                  <div className="hidden md:block absolute top-10 left-0 right-0 h-0.5 bg-gray-700"></div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                    {implementationSteps.map((step, index) => (
                      <div key={index} className="relative flex flex-col items-center text-center">
                        <div className="w-12 h-12 rounded-full bg-gray-700 border-4 border-gray-800 flex items-center justify-center z-10 mb-3">
                          <span className="text-indigo-400 font-bold">{index + 1}</span>
                        </div>
                        <h4 className="font-semibold text-white mb-1">{step.title}</h4>
                        <p className="text-sm text-gray-400 mb-2">{step.description}</p>
                        <span className="text-xs px-2 py-0.5 rounded-full bg-gray-700 text-indigo-300">
                          {step.duration}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              

              
              {/* FAQs */}
              <div className="mb-12">
                <div className="flex items-center gap-2 mb-6">
                  <FaQuestionCircle className="text-indigo-400" />
                  <h3 className="text-xl font-semibold text-white">Frequently Asked Questions</h3>
                </div>
                <div className="space-y-4">
                  {faqs.map((faq, index) => (
                    <div 
                      key={index} 
                      className="bg-gray-800 rounded-xl shadow-sm border border-gray-700 overflow-hidden"
                    >
                      <button 
                        onClick={() => setActiveFaq(activeFaq === index ? null : index)}
                        className="flex items-center justify-between w-full p-5 text-left"
                      >
                        <span className="font-medium text-white">{faq.question}</span>
                        <span className={`transition-transform duration-200 ${activeFaq === index ? 'rotate-180' : ''}`}>
                          <IoChevronForwardOutline className="w-5 h-5 text-gray-400 transform rotate-90" />
                        </span>
                      </button>
                      
                      {activeFaq === index && (
                        <div className="px-5 pb-5">
                          <div className="border-t border-gray-700 pt-4">
                            <p className="text-gray-300">{faq.answer}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          
          {/* Right column - Features and CTA */}
          <div className="lg:col-span-4 space-y-8">
            {/* ROI Calculator / Feature Card */}
            {showCalculator ? (
              <ROICalculator />
            ) : (
              <div className="bg-gray-800 rounded-lg shadow-md border border-gray-700 p-6">
                <div className="flex items-center gap-3 mb-4">
                  <div className="p-2 rounded-full bg-gray-700">
                    <FaChartBar className="w-5 h-5 text-indigo-400" />
                  </div>
                  <h3 className="text-lg font-semibold text-white">Calculate Your ROI</h3>
                </div>
                <p className="text-gray-400 mb-6">
                  See how much time and money {currentIndustry.title} Analytics can save your organization.
                </p>
                <button 
                  onClick={() => setShowCalculator(true)}
                  className="w-full py-3 bg-indigo-600 hover:bg-indigo-700 text-white rounded-lg transition-colors flex items-center justify-center gap-2"
                >
                  Open ROI Calculator <FaArrowRight className="text-sm" />
                </button>
              </div>
            )}
            
            {/* Features Card */}
            <div className="bg-gray-800 rounded-lg shadow-md border border-gray-700 p-6">
              <div className="flex items-center gap-3 mb-4">
                <div className="p-2 rounded-full bg-gray-700">
                  <FaLayerGroup className="w-5 h-5 text-indigo-400" />
                </div>
                <h3 className="text-lg font-semibold text-white">Key Features</h3>
              </div>
              <ul className="space-y-3 mb-6">
                {currentIndustry.features.map((feature, index) => (
                  <li key={index} className="flex items-start gap-3">
                    <span className="p-1 flex-shrink-0 mt-0.5 rounded-full bg-gray-700">
                      <FaCheck className="w-3 h-3 text-indigo-400" />
                    </span>
                    <span className="text-gray-300">{feature}</span>
                  </li>
                ))}
              </ul>
              <button 
                onClick={() => setShowComparison(true)}
                className="w-full py-2 bg-gray-700 hover:bg-gray-600 text-gray-300 rounded-lg font-medium transition-colors flex items-center justify-center gap-2"
              >
                Compare All Features <FaInfoCircle className="text-sm" />
              </button>
            </div>
            
            {/* CTA Card */}
            <div className="bg-gray-700 rounded-lg shadow-md border border-gray-600 p-6">
              <div className="flex items-center gap-3 mb-4">
                <div className="p-2 rounded-full bg-gray-800 bg-opacity-30">
                  <FaClock className="w-5 h-5 text-indigo-400" />
                </div>
                <h3 className="text-lg font-semibold text-white">Ready to Get Started?</h3>
              </div>
              <p className="text-gray-300 mb-6">
                Our team will guide you through implementing the right {currentIndustry.title.toLowerCase()} solution for your business.
              </p>
              <div className="space-y-3">
                <button className="w-full py-3 bg-indigo-600 hover:bg-indigo-700 text-white rounded-lg transition-colors flex items-center justify-center gap-2">
                  Schedule a Demo <FaArrowRight className="text-sm" />
                </button>
                <button className="w-full py-3 bg-gray-800 bg-opacity-30 hover:bg-opacity-40 text-white rounded-lg transition-colors">
                  View Pricing Options
                </button>
              </div>
            </div>
          </div>
        </div>
        
        {/* Bottom CTA */}
        <div className="mt-16 bg-gradient-to-r from-indigo-900 to-indigo-800 rounded-xl shadow-lg p-8 text-white">
          <div className="flex flex-col md:flex-row md:items-center justify-between gap-6">
            <div>
              <h3 className="text-2xl font-bold mb-2">Ready to transform your {currentIndustry.title.toLowerCase()} data?</h3>
              <p className="text-indigo-100">
                Join the organizations using JaanchAI to make faster, smarter decisions with their data.
              </p>
            </div>
            <div className="flex flex-col sm:flex-row gap-3">
              <button className="px-6 py-3 bg-white text-gray-900 rounded-lg font-medium hover:bg-gray-100 transition-colors">
                Get Started
              </button>
              <button className="px-6 py-3 bg-indigo-800/40 hover:bg-indigo-800/60 border border-indigo-400/30 rounded-lg font-medium transition-colors">
                Learn More
              </button>
            </div>
          </div>
        </div>
      </div>
      
      {/* Modals */}
      {showComparison && <FeatureComparisonModal />}
    </section>
  );
};

export default Solutions;