import React, { useMemo, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend } from 'recharts';
import { motion } from 'framer-motion';
import { Search, Award, Trophy, ArrowUpDown, Sun, Moon, Check, Users, ShoppingBag, Calendar, Repeat } from 'lucide-react';

const TabList = ({ children }) => (
  <div className="flex space-x-2 mb-4 bg-gray-800/50 p-1 rounded-lg">
    {children}
  </div>
);

const Tab = ({ selected, onClick, children }) => (
  <button
    className={`px-4 py-2 rounded-md text-sm font-medium transition-colors
      ${selected ? 'bg-blue-500 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-700/50'}`}
    onClick={onClick}
  >
    {children}
  </button>
);

// Convert number to lakhs
const formatToLakhs = (value) => {
  const lakhValue = value / 100000;
  return `₹${lakhValue.toFixed(2)}L`;
};

const CustomerAnalysisChart = ({ data }) => {
  const [activeTab, setActiveTab] = useState('sales');
  const [searchTerm, setSearchTerm] = useState('');
  const [theme, setTheme] = useState('dark');
  const [displayCount, setDisplayCount] = useState(10);
  const [selectedCustomers, setSelectedCustomers] = useState(new Set());
  const [sortConfig, setSortConfig] = useState({
    key: 'sales',
    direction: 'desc'
  });

  const toggleCustomerSelection = (customerName) => {
    const newSelected = new Set(selectedCustomers);
    if (newSelected.has(customerName)) {
      newSelected.delete(customerName);
    } else if (newSelected.size < 5) { // Limit to 5 customers for comparison
      newSelected.add(customerName);
    }
    setSelectedCustomers(newSelected);
  };

  const getChartData = (data, metric) => {
    if (!data?.data) return [];

    let processedData = data.data
      .map(customer => ({
        name: customer.CUSTOMER_NAME,
        sales: customer.NET_AMOUNT,
        quantity: customer.TOTAL_QTY,
        orders: customer.ORDER_COUNT,
        avgOrderValue: customer.AVG_ORDER_VALUE,
        recency: customer.DAYS_SINCE_LAST_ORDER,
        branches: customer.BRANCH_NAMES,
        selected: selectedCustomers.has(customer.CUSTOMER_NAME)
      }))
      .sort((a, b) => b[metric] - a[metric]);

    return selectedCustomers.size > 0 
      ? processedData.filter(customer => selectedCustomers.has(customer.name))
      : processedData;
  };

  const chartData = useMemo(() => getChartData(data, activeTab), [data, activeTab, selectedCustomers]);

  const tableData = useMemo(() => {
    if (!data?.data) return [];
    
    let processedData = data.data
      .map(customer => ({
        name: customer.CUSTOMER_NAME,
        salesFormatted: formatToLakhs(customer.NET_AMOUNT),
        sales: customer.NET_AMOUNT,
        quantity: customer.TOTAL_QTY,
        orders: customer.ORDER_COUNT,
        avgOrderValue: customer.AVG_ORDER_VALUE,
        avgOrderValueFormatted: formatToLakhs(customer.AVG_ORDER_VALUE),
        recency: customer.DAYS_SINCE_LAST_ORDER,
        firstOrder: new Date(customer.FIRST_ORDER_DATE).toLocaleDateString(),
        lastOrder: new Date(customer.LAST_ORDER_DATE).toLocaleDateString(),
        branches: customer.BRANCH_NAMES,
        selected: selectedCustomers.has(customer.CUSTOMER_NAME)
      }))
      .filter(customer => 
        customer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customer.branches.toLowerCase().includes(searchTerm.toLowerCase())
      );

    processedData.sort((a, b) => {
      const aValue = parseFloat(a[sortConfig.key]) || 0;
      const bValue = parseFloat(b[sortConfig.key]) || 0;
      return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
    });

    return processedData;
  }, [data, searchTerm, sortConfig, selectedCustomers]);

  const toggleSort = (key) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'desc' ? 'asc' : 'desc'
    }));
  };

  const getRecencyColor = (days) => {
    if (days <= 30) return 'text-green-400';
    if (days <= 90) return 'text-yellow-400';
    return 'text-red-400';
  };

  const getAvgOrderValueColor = (value) => {
    const numValue = parseFloat(value);
    if (numValue >= 50000) return 'text-green-400';
    if (numValue >= 25000) return 'text-yellow-400';
    return 'text-gray-400';
  };

  const SortHeader = ({ label, sortKey }) => (
    <th className="p-4 text-sm font-medium text-gray-400">
      <button 
        className="flex items-center space-x-1 hover:text-gray-200"
        onClick={() => toggleSort(sortKey)}
      >
        <span>{label}</span>
        <ArrowUpDown className="w-4 h-4" />
      </button>
    </th>
  );

  const metrics = [
    { id: 'sales', label: 'By Sales', icon: <ShoppingBag className="w-4 h-4" /> },
    { id: 'orders', label: 'By Orders', icon: <ShoppingBag className="w-4 h-4" /> },
    { id: 'avgOrderValue', label: 'By Avg. Order', icon: <Award className="w-4 h-4" /> },
    { id: 'recency', label: 'By Recency', icon: <Calendar className="w-4 h-4" /> }
  ];

  // Function to get bar colors based on metric
  const getBarColor = (entry, index, activeTab) => {
    if (activeTab === 'recency') {
      // Inverse color scale for recency (lower is better)
      return `hsl(${Math.max(0, 120 - entry.recency/2)}, 70%, 50%)`;
    } else if (activeTab === 'avgOrderValue') {
      return `hsl(${Math.min(120, entry.avgOrderValue/1000)}, 70%, 50%)`;
    } else {
      // Default color scale
      return `hsl(${200 + index * 20}, 70%, 50%)`;
    }
  };

  return (
    <div className={`space-y-8 ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Customer Analysis Dashboard</h1>
        <div className="flex items-center space-x-4">
          <span className="text-sm text-gray-400">
            {selectedCustomers.size} of 5 customers selected
          </span>
          <button
            onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
            className="p-2 rounded-full bg-gray-800/50 hover:bg-gray-700/50 transition-colors"
          >
            {theme === 'dark' ? <Sun className="text-yellow-400" /> : <Moon className="text-gray-400" />}
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className={`p-6 rounded-xl ${theme === 'dark' ? 'bg-gray-800/50' : 'bg-gray-100'}`}>
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-sm font-medium text-gray-400">Total Sales</h3>
            <ShoppingBag className="w-5 h-5 text-blue-400" />
          </div>
          <p className="text-2xl font-bold">
            {data?.data ? formatToLakhs(data.data.reduce((sum, cust) => sum + cust.NET_AMOUNT, 0)) : '₹0L'}
          </p>
        </div>
        <div className={`p-6 rounded-xl ${theme === 'dark' ? 'bg-gray-800/50' : 'bg-gray-100'}`}>
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-sm font-medium text-gray-400">Total Customers</h3>
            <Users className="w-5 h-5 text-purple-400" />
          </div>
          <p className="text-2xl font-bold">{data?.total_records || 0}</p>
        </div>
        <div className={`p-6 rounded-xl ${theme === 'dark' ? 'bg-gray-800/50' : 'bg-gray-100'}`}>
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-sm font-medium text-gray-400">Avg. Order Value</h3>
            <Award className="w-5 h-5 text-green-400" />
          </div>
          <p className="text-2xl font-bold">
            {data?.data && data.data.length > 0 
              ? formatToLakhs(data.data.reduce((sum, cust) => sum + cust.AVG_ORDER_VALUE, 0) / data.data.length) 
              : '₹0L'}
          </p>
        </div>
        <div className={`p-6 rounded-xl ${theme === 'dark' ? 'bg-gray-800/50' : 'bg-gray-100'}`}>
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-sm font-medium text-gray-400">Total Orders</h3>
            <Repeat className="w-5 h-5 text-yellow-400" />
          </div>
          <p className="text-2xl font-bold">
            {data?.data ? data.data.reduce((sum, cust) => sum + cust.ORDER_COUNT, 0) : 0}
          </p>
        </div>
      </div>

      <div className="space-y-6">
        <TabList>
          {metrics.map(metric => (
            <Tab 
              key={metric.id}
              selected={activeTab === metric.id} 
              onClick={() => setActiveTab(metric.id)}
            >
              <div className="flex items-center space-x-2">
                {metric.icon}
                <span>{metric.label}</span>
              </div>
            </Tab>
          ))}
        </TabList>

        <motion.div 
          key={activeTab}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={`h-[600px] w-full ${theme === 'dark' ? 'bg-gray-900/90 border-gray-800' : 'bg-white border-gray-200'} border rounded-xl p-6 overflow-y-auto`}
        >
          <ResponsiveContainer height={Math.max(400, chartData.length * 50)}>
            <BarChart
              data={chartData}
              layout="vertical"
              margin={{ top: 5, right: 30, left: 100, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke={theme === 'dark' ? '#374151' : '#E5E7EB'} />
              <XAxis
                type="number"
                stroke={theme === 'dark' ? '#6B7280' : '#4B5563'}
                tick={{ fill: theme === 'dark' ? '#9CA3AF' : '#6B7280', fontSize: 12 }}
              />
              <YAxis
                type="category"
                dataKey="name"
                stroke={theme === 'dark' ? '#6B7280' : '#4B5563'}
                tick={{ fill: theme === 'dark' ? '#9CA3AF' : '#6B7280', fontSize: 12 }}
              />
              <Tooltip
                content={({ active, payload }) => {
                  if (!active || !payload?.length) return null;
                  const data = payload[0].payload;
                  
                  return (
                    <div className={`${theme === 'dark' ? 'bg-gray-900 border-gray-800' : 'bg-white border-gray-200'} border p-4 rounded-lg shadow-lg`}>
                      <p className="text-sm font-medium text-gray-200 mb-2">{data.name}</p>
                      <p className="text-xs text-gray-400">Sales: {formatToLakhs(data.sales)}</p>
                      <p className="text-xs text-gray-400">Orders: {data.orders}</p>
                      <p className="text-xs text-gray-400">Avg. Order: {formatToLakhs(data.avgOrderValue)}</p>
                      <p className="text-xs text-gray-400">Days Since Last Order: {data.recency}</p>
                      <p className="text-xs text-gray-400">Quantity: {data.quantity}</p>
                      <p className="text-xs text-gray-400">Branches: {data.branches}</p>
                    </div>
                  );
                }}
              />
              <Bar dataKey={activeTab}>
                {chartData.map((entry, index) => (
                  <Cell 
                    key={`cell-${index}`}
                    fill={getBarColor(entry, index, activeTab)}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </motion.div>
      </div>

      {/* Table Component */}
      <div className={`${theme === 'dark' ? 'bg-gray-900/90 border-gray-800' : 'bg-white border-gray-200'} border rounded-xl overflow-hidden`}>
        <div className="p-4 border-b border-gray-800">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
            <input
              type="text"
              placeholder="Search customers..."
              className={`w-full pl-10 pr-4 py-2 ${theme === 'dark' ? 'bg-gray-800 border-gray-700 text-gray-200' : 'bg-gray-100 border-gray-200 text-gray-900'} border rounded-lg placeholder-gray-500 focus:outline-none focus:border-blue-500`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className={`${theme === 'dark' ? 'bg-gray-800/50' : 'bg-gray-100'}`}>
                <th className="text-left p-4 text-sm font-medium text-gray-400">Select</th>
                <th className="text-left p-4 text-sm font-medium text-gray-400">Customer Name</th>
                <SortHeader label="Sales" sortKey="sales" />
                <SortHeader label="Orders" sortKey="orders" />
                <SortHeader label="Avg. Order" sortKey="avgOrderValue" />
                <SortHeader label="Days Since Last" sortKey="recency" />
                <th className="text-left p-4 text-sm font-medium text-gray-400">First Order</th>
                <th className="text-left p-4 text-sm font-medium text-gray-400">Last Order</th>
                <th className="text-left p-4 text-sm font-medium text-gray-400">Branches</th>
              </tr>
            </thead>
            <tbody className={`divide-y ${theme === 'dark' ? 'divide-gray-800' : 'divide-gray-200'}`}>
              {tableData.slice(0, displayCount).map((customer, index) => (
                <tr key={index} className={`hover:${theme === 'dark' ? 'bg-gray-800/50' : 'bg-gray-100'} transition-colors`}>
                  <td className="p-4">
                    <button
                      onClick={() => toggleCustomerSelection(customer.name)}
                      className={`p-2 rounded-full ${customer.selected ? 'bg-blue-500' : 'bg-gray-700'} hover:bg-opacity-80 transition-colors`}
                    >
                      {customer.selected && <Check className="w-4 h-4 text-white" />}
                    </button>
                  </td>
                  <td className="p-4 text-sm text-gray-300">{customer.name}</td>
                  <td className="p-4 text-sm text-gray-300 text-right">{customer.salesFormatted}</td>
                  <td className="p-4 text-sm text-gray-300 text-right">{customer.orders}</td>
                  <td className={`p-4 text-sm text-right ${getAvgOrderValueColor(customer.avgOrderValue)}`}>
                    {customer.avgOrderValueFormatted}
                  </td>
                  <td className={`p-4 text-sm text-right ${getRecencyColor(customer.recency)}`}>
                    {customer.recency} days
                  </td>
                  <td className="p-4 text-sm text-gray-300">{customer.firstOrder}</td>
                  <td className="p-4 text-sm text-gray-300">{customer.lastOrder}</td>
                  <td className="p-4 text-sm text-gray-300">{customer.branches}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {tableData.length > displayCount && (
          <div className="p-4 flex justify-center">
            <button
              onClick={() => setDisplayCount(prev => prev + 10)}
              className={`px-4 py-2 ${theme === 'dark' ? 'bg-gray-800/50 text-white' : 'bg-gray-100 text-gray-900'} rounded-lg hover:bg-gray-700/50 transition-colors`}
            >
              Load More
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerAnalysisChart;