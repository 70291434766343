import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Zap, 
  Brain, 
  Database, 
  Activity,
  Building,
  ShoppingBag,
  Stethoscope,
  Factory
} from 'lucide-react';

const features = [
  {
    title: "Unified Business Analytics",
    query: "Show quarterly performance across all business units",
    steps: [
      "Connecting data sources...",
      "Normalizing metrics across departments...",
      "Generated insights!"
    ],
    response: {
      type: "business-units",
      data: [
        { 
          unit: "Operations", 
          efficiency: "87%", 
          growth: 14, 
          metrics: ["Cost Reduction: 8%", "Process Optimization: 12%"]
        },
        { 
          unit: "Sales", 
          efficiency: "92%", 
          growth: 24, 
          metrics: ["Conversion Rate: 4.2%", "Customer Retention: 78%"]
        },
        { 
          unit: "Supply Chain", 
          efficiency: "83%", 
          growth: 16, 
          metrics: ["Inventory Turnover: 12x", "Fulfillment Rate: 96%"]
        }
      ]
    }
  },
  {
    title: "Vendor Performance Analysis",
    query: "Compare top 3 vendors by delivery time and quality",
    steps: [
      "Aggregating vendor data...",
      "Analyzing performance metrics...",
      "Ranking analysis complete:"
    ],
    response: {
      type: "vendor-comparison",
      data: [
        { 
          name: "Vendor A", 
          onTimeDelivery: 97, 
          qualityScore: 92, 
          costEfficiency: "High"
        },
        { 
          name: "Vendor B", 
          onTimeDelivery: 94, 
          qualityScore: 96, 
          costEfficiency: "Medium"
        },
        { 
          name: "Vendor C", 
          onTimeDelivery: 89, 
          qualityScore: 87, 
          costEfficiency: "High"
        }
      ]
    }
  },
  {
    title: "Cross-Sector Analysis",
    query: "Compare performance across different sectors",
    steps: [
      "Processing multi-sector data...",
      "Normalizing sector-specific metrics...",
      "Sector comparison complete:"
    ],
    response: {
      type: "sector-performance",
      data: [
        { sector: "Healthcare", revenue: "₹4.2Cr", growth: 18, efficiency: 87 },
        { sector: "Retail", revenue: "₹3.8Cr", growth: 22, efficiency: 91 },
        { sector: "Manufacturing", revenue: "₹6.5Cr", growth: 14, efficiency: 84 },
        { sector: "Services", revenue: "₹2.9Cr", growth: 26, efficiency: 92 }
      ]
    }
  },
  {
    title: "Operational Bottlenecks",
    query: "Identify bottlenecks in our operations workflow",
    steps: [
      "Analyzing process timelines...",
      "Identifying congestion points...",
      "Bottlenecks identified:"
    ],
    response: {
      type: "bottlenecks",
      data: {
        workflows: [
          { 
            stage: "Procurement",
            delay: "32%",
            impact: "High",
            recommendation: "Streamline approval process"
          },
          { 
            stage: "Inventory Management",
            delay: "18%",
            impact: "Medium",
            recommendation: "Implement real-time tracking"
          },
          { 
            stage: "Quality Control",
            delay: "24%",
            impact: "High",
            recommendation: "Add parallel inspection stations"
          }
        ]
      }
    }
  },
  {
    title: "Resource Optimization",
    query: "Analyze resource utilization and suggest improvements",
    steps: [
      "Processing utilization data...",
      "Identifying optimization opportunities...",
      "Analysis complete:"
    ],
    response: {
      type: "resource-optimization",
      data: {
        resources: [
          {
            type: "Human Resources",
            utilization: 78,
            potential: 92,
            suggestions: ["Cross-training", "Workflow automation"]
          },
          {
            type: "Infrastructure",
            utilization: 65,
            potential: 85,
            suggestions: ["Capacity sharing", "Peak scheduling"]
          },
          {
            type: "Equipment",
            utilization: 72,
            potential: 90,
            suggestions: ["Preventive maintenance", "Usage optimization"]
          }
        ]
      }
    }
  }
];

const renderVisualization = (response) => {
  switch (response.type) {
    case "business-units":
      return (
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-3 gap-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {response.data.map((unit) => (
            <div key={unit.unit} className="bg-gray-800/50 p-4 rounded-xl">
              <h3 className="text-lg font-medium mb-2">{unit.unit}</h3>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-400">Efficiency</span>
                  <span className="text-emerald-400">{unit.efficiency}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-400">Growth</span>
                  <span className="text-blue-400">+{unit.growth}%</span>
                </div>
                <div className="mt-4">
                  <div className="text-sm text-gray-400 mb-1">Key Metrics</div>
                  <div className="flex flex-col gap-1">
                    {unit.metrics.map(metric => (
                      <span key={metric} className="px-2 py-1 bg-gray-700/50 rounded-md text-xs">
                        {metric}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </motion.div>
      );

    case "vendor-comparison":
      return (
        <motion.div 
          className="space-y-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {response.data.map((vendor, i) => (
            <motion.div 
              key={vendor.name}
              className="bg-gray-800/50 p-4 rounded-xl"
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: i * 0.1 }}
            >
              <div className="flex justify-between items-center mb-3">
                <h3 className="font-medium">{vendor.name}</h3>
                <span className={`px-2 py-1 rounded text-xs ${
                  vendor.costEfficiency === 'High' ? 'bg-emerald-500/20 text-emerald-400' : 
                  'bg-yellow-500/20 text-yellow-400'
                }`}>
                  {vendor.costEfficiency} Efficiency
                </span>
              </div>
              
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <div className="text-xs text-gray-500 mb-1">On-Time Delivery</div>
                  <div className="flex items-center">
                    <div className="h-2 rounded-full bg-gray-700 w-full">
                      <div 
                        className="h-2 rounded-full bg-blue-500" 
                        style={{ width: `${vendor.onTimeDelivery}%` }}
                      ></div>
                    </div>
                    <span className="ml-2 text-sm">{vendor.onTimeDelivery}%</span>
                  </div>
                </div>
                
                <div>
                  <div className="text-xs text-gray-500 mb-1">Quality Score</div>
                  <div className="flex items-center">
                    <div className="h-2 rounded-full bg-gray-700 w-full">
                      <div 
                        className="h-2 rounded-full bg-purple-500" 
                        style={{ width: `${vendor.qualityScore}%` }}
                      ></div>
                    </div>
                    <span className="ml-2 text-sm">{vendor.qualityScore}%</span>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      );

    case "sector-performance":
      return (
        <motion.div className="grid grid-cols-2 gap-4">
          {response.data.map((sector, i) => (
            <motion.div
              key={sector.sector}
              className="bg-gray-800/50 p-4 rounded-xl flex items-center justify-between"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: i * 0.1 }}
              whileHover={{ scale: 1.02 }}
            >
              <div>
                <div className="flex items-center text-gray-300">
                  {sector.sector === "Healthcare" ? (
                    <Stethoscope className="w-4 h-4 mr-2 text-red-400" />
                  ) : sector.sector === "Retail" ? (
                    <ShoppingBag className="w-4 h-4 mr-2 text-blue-400" />
                  ) : sector.sector === "Manufacturing" ? (
                    <Factory className="w-4 h-4 mr-2 text-orange-400" />
                  ) : (
                    <Building className="w-4 h-4 mr-2 text-purple-400" />
                  )}
                  {sector.sector}
                </div>
                <div className="text-sm text-gray-500">Revenue: {sector.revenue}</div>
              </div>
              <div className="text-emerald-400">+{sector.growth}%</div>
            </motion.div>
          ))}
        </motion.div>
      );

    case "bottlenecks":
      return (
        <motion.div className="space-y-4">
          {response.data.workflows.map((workflow, i) => (
            <motion.div
              key={workflow.stage}
              className="bg-gray-800/50 p-4 rounded-xl"
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: i * 0.1 }}
            >
              <div className="flex justify-between items-center mb-2">
                <h3 className="font-medium">{workflow.stage}</h3>
                <span className={`text-sm ${
                  workflow.impact === 'High' ? 'text-red-400' : 
                  workflow.impact === 'Medium' ? 'text-yellow-400' : 'text-blue-400'
                }`}>
                  {workflow.impact} Impact
                </span>
              </div>
              <div className="flex items-center mb-3">
                <div className="text-sm text-gray-400 mr-2">Delay:</div>
                <div className="h-2 w-24 bg-gray-700 rounded-full">
                  <div 
                    className="h-2 bg-red-500 rounded-full" 
                    style={{ width: workflow.delay }}
                  ></div>
                </div>
                <div className="ml-2 text-red-400">{workflow.delay}</div>
              </div>
              <div className="bg-gray-700/30 px-3 py-2 rounded text-sm">
                <span className="text-blue-400">Recommendation:</span> {workflow.recommendation}
              </div>
            </motion.div>
          ))}
        </motion.div>
      );

    case "resource-optimization":
      return (
        <motion.div className="space-y-4">
          {response.data.resources.map((resource, i) => (
            <motion.div
              key={resource.type}
              className="bg-gray-800/50 p-4 rounded-xl"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: i * 0.1 }}
            >
              <h3 className="font-medium mb-3">{resource.type}</h3>
              <div className="space-y-3">
                <div>
                  <div className="flex justify-between text-sm mb-1">
                    <span className="text-gray-400">Current Utilization</span>
                    <span>{resource.utilization}%</span>
                  </div>
                  <div className="h-2 w-full bg-gray-700 rounded-full">
                    <div 
                      className="h-2 bg-blue-500 rounded-full" 
                      style={{ width: `${resource.utilization}%` }}
                    ></div>
                  </div>
                </div>
                <div>
                  <div className="flex justify-between text-sm mb-1">
                    <span className="text-gray-400">Potential Utilization</span>
                    <span>{resource.potential}%</span>
                  </div>
                  <div className="h-2 w-full bg-gray-700 rounded-full">
                    <div 
                      className="h-2 bg-emerald-500 rounded-full" 
                      style={{ width: `${resource.potential}%` }}
                    ></div>
                  </div>
                </div>
                <div>
                  <div className="text-sm text-gray-400 mb-1">Suggestions</div>
                  <div className="flex flex-wrap gap-2">
                    {resource.suggestions.map(suggestion => (
                      <span key={suggestion} className="px-2 py-1 bg-blue-500/20 text-blue-400 rounded text-xs">
                        {suggestion}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      );

    default:
      return null;
  }
};


const JaanchCopilot = () => {
  const [currentFeature, setCurrentFeature] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!isFocused) {
      const stepInterval = setInterval(() => {
        setCurrentStep(prev => {
          if (prev >= features[currentFeature].steps.length - 1) {
            clearInterval(stepInterval);
            return prev;
          }
          return prev + 1;
        });
      }, 800);

      const featureInterval = setInterval(() => {
        setCurrentFeature(prev => (prev + 1) % features.length);
        setCurrentStep(0);
      }, 6000);

      return () => {
        clearInterval(stepInterval);
        clearInterval(featureInterval);
      };
    }
  }, [currentFeature, isFocused]);

  const terminalVariants = {
    initial: { 
      opacity: 0, 
      y: 20,
      scale: 0.95
    },
    animate: { 
      opacity: 1, 
      y: 0,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    },
    exit: { 
      opacity: 0,
      y: -20,
      scale: 0.95,
      transition: {
        duration: 0.3
      }
    }
  };

  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-900 via-gray-900 to-black text-white relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,_var(--tw-gradient-stops))] from-blue-500/10 via-purple-500/5 to-transparent" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,_var(--tw-gradient-stops))] from-emerald-500/5 via-transparent to-transparent" />
      </div>

      <div className="container mx-auto px-4 py-20 relative z-10">
        {/* Hero Section */}
        <div className="text-center mb-20">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-blue-500/10 border border-blue-500/20 mb-6"
          >
            <Database className="w-4 h-4 text-blue-400" />
            <span className="text-sm text-blue-400">Data Intelligence Platform</span>
          </motion.div>

          <motion.h1 
            className="text-4xl md:text-6xl font-bold mb-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            <span className="bg-gradient-to-r from-blue-400 via-purple-400 to-emerald-400 bg-clip-text text-transparent">
              Transform Cluttered Data Into Clarity
            </span>
          </motion.h1>
          
          <motion.p 
            className="text-gray-400 max-w-2xl mx-auto mb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            Ask simple questions and get powerful insights. JaanchAI streamlines your business data
            to deliver actionable intelligence across all operations.
          </motion.p>
        </div>

        {/* Main Terminal */}
        <div className="max-w-4xl mx-auto">
          <motion.div 
            initial="initial"
            animate="animate"
            exit="exit"
            variants={terminalVariants}
            className="bg-gray-900/50 backdrop-blur-xl border border-gray-800/50 rounded-2xl overflow-hidden shadow-2xl"
            onMouseEnter={() => setIsFocused(true)}
            onMouseLeave={() => setIsFocused(false)}
          >
            {/* Terminal Header */}
            <div className="flex items-center justify-between px-4 py-3 bg-gray-800/50 border-b border-gray-700/50">
              <div className="flex gap-2">
                <div className="w-3 h-3 rounded-full bg-red-500/50" />
                <div className="w-3 h-3 rounded-full bg-yellow-500/50" />
                <div className="w-3 h-3 rounded-full bg-green-500/50" />
              </div>
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-400">jaanch@analytics</span>
                <div className="w-2 h-2 rounded-full bg-emerald-500 animate-pulse" />
              </div>
              <div className="flex items-center gap-2 text-gray-500 text-sm">
                <Brain className="w-4 h-4" />
                <span>Intelligent Analysis</span>
              </div>
            </div>

            {/* Terminal Content */}
            <div className="p-6 font-mono">
              <AnimatePresence mode="wait">
                <motion.div
                  key={`${currentFeature}-${currentStep}`}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.3 }}
                  className="space-y-4"
                >
                  {/* Query Input */}
                  <div className="flex items-center gap-2 text-gray-400">
                    <Zap className="w-4 h-4 text-yellow-400" />
                    <span className="text-sm">$</span>
                    <span className="text-emerald-400">{features[currentFeature].query}</span>
                    <span className="animate-pulse">▌</span>
                  </div>

                  {/* Processing Steps */}
                  <div className="pl-6">
                    <div className="text-blue-400 mb-4 flex items-center gap-2">
                      <Activity className="w-4 h-4 animate-spin" />
                      {features[currentFeature].steps[currentStep]}
                    </div>
                    
                    {/* Visualization */}
                    {currentStep === features[currentFeature].steps.length - 1 && (
                      <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.3 }}
                        className="mt-6"
                      >
                        {renderVisualization(features[currentFeature].response)}
                      </motion.div>
                    )}
                  </div>
                </motion.div>
              </AnimatePresence>
            </div>
          </motion.div>

          {/* Quick Actions */}
          <motion.div 
            className="mt-8 flex justify-center gap-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            <button 
              onClick={() => setIsFocused(!isFocused)}
              className="px-4 py-2 bg-gray-800/50 border border-gray-700/50 rounded-lg text-gray-400 hover:text-white hover:border-gray-600 transition-all"
            >
              {isFocused ? "Resume Demo" : "Pause Demo"}
            </button>
            <button className="px-4 py-2 bg-blue-500/10 border border-blue-500/20 rounded-lg text-blue-400 hover:bg-blue-500/20 transition-all flex items-center gap-2">
              <Database className="w-4 h-4" />
              Try JaanchAI Free
            </button>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default JaanchCopilot;