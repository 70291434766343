import React, { useMemo, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend } from 'recharts';
import { motion } from 'framer-motion';
import { Search, Award, Trophy, ArrowUpDown, Sun, Moon, Check } from 'lucide-react';

const TabList = ({ children }) => (
  <div className="flex space-x-2 mb-4 bg-gray-800/50 p-1 rounded-lg">
    {children}
  </div>
);

const Tab = ({ selected, onClick, children }) => (
  <button
    className={`px-4 py-2 rounded-md text-sm font-medium transition-colors
      ${selected ? 'bg-blue-500 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-700/50'}`}
    onClick={onClick}
  >
    {children}
  </button>
);

// Convert number to lakhs
const formatToLakhs = (value) => {
  const lakhValue = value / 100000;
  return `₹${lakhValue.toFixed(2)}L`;
};

const VendorAnalysisChart = ({ data }) => {
  const [activeTab, setActiveTab] = useState('sales');
  const [searchTerm, setSearchTerm] = useState('');
  const [theme, setTheme] = useState('dark');
  const [displayCount, setDisplayCount] = useState(10);
  const [selectedVendors, setSelectedVendors] = useState(new Set());
  const [sortConfig, setSortConfig] = useState({
    key: 'sales',
    direction: 'desc'
  });

  const toggleVendorSelection = (vendorName) => {
    const newSelected = new Set(selectedVendors);
    if (newSelected.has(vendorName)) {
      newSelected.delete(vendorName);
    } else if (newSelected.size < 5) { // Limit to 5 vendors for comparison
      newSelected.add(vendorName);
    }
    setSelectedVendors(newSelected);
  };

  const getChartData = (data, metric) => {
    if (!data?.data) return [];

    let processedData = data.data
      .map(vendor => ({
        name: vendor.PARTY_NAME,
        sales: vendor.ADJUSTED_SALES_AMOUNT,
        purchase: vendor.NET_AMOUNT_PURCHASE,
        margin: ((vendor.ADJUSTED_SALES_AMOUNT - vendor.NET_AMOUNT_PURCHASE) / vendor.NET_AMOUNT_PURCHASE * 100).toFixed(2),
        discount: ((vendor.CD_PURCHASE / vendor.GROSS_AMOUNT_PURCHASE) * 100).toFixed(2),
        quantity: vendor.TOTAL_QTY_PURCHASE,
        branches: vendor.BRANCH_NAMES,
        selected: selectedVendors.has(vendor.PARTY_NAME)
      }))
      .sort((a, b) => b[metric] - a[metric]);

    return selectedVendors.size > 0 
      ? processedData.filter(vendor => selectedVendors.has(vendor.name))
      : processedData;
  };

  const chartData = useMemo(() => getChartData(data, activeTab), [data, activeTab, selectedVendors]);

  const tableData = useMemo(() => {
    if (!data?.data) return [];
    
    let processedData = data.data
      .map(vendor => ({
        name: vendor.PARTY_NAME,
        sales: vendor.ADJUSTED_SALES_AMOUNT,
        salesFormatted: formatToLakhs(vendor.ADJUSTED_SALES_AMOUNT),
        purchase: vendor.NET_AMOUNT_PURCHASE,
        purchaseFormatted: formatToLakhs(vendor.NET_AMOUNT_PURCHASE),
        margin: ((vendor.ADJUSTED_SALES_AMOUNT - vendor.NET_AMOUNT_PURCHASE) / vendor.NET_AMOUNT_PURCHASE * 100).toFixed(2),
        discount: ((vendor.CD_PURCHASE / vendor.GROSS_AMOUNT_PURCHASE) * 100).toFixed(2),
        quantity: vendor.TOTAL_QTY_PURCHASE,
        branches: vendor.BRANCH_NAMES,
        selected: selectedVendors.has(vendor.PARTY_NAME)
      }))
      .filter(vendor => 
        vendor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        vendor.branches.toLowerCase().includes(searchTerm.toLowerCase())
      );

    processedData.sort((a, b) => {
      const aValue = parseFloat(a[sortConfig.key]) || 0;
      const bValue = parseFloat(b[sortConfig.key]) || 0;
      return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
    });

    return processedData;
  }, [data, searchTerm, sortConfig, selectedVendors]);

  const toggleSort = (key) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'desc' ? 'asc' : 'desc'
    }));
  };

  const getColorForValue = (value, type) => {
    if (type === 'margin') {
      if (value >= 20) return 'text-green-400';
      if (value >= 10) return 'text-yellow-400';
      return 'text-red-400';
    }
    if (type === 'discount') {
      if (value >= 15) return 'text-red-400';
      if (value >= 8) return 'text-yellow-400';
      return 'text-green-400';
    }
    return '';
  };

  const SortHeader = ({ label, sortKey }) => (
    <th className="p-4 text-sm font-medium text-gray-400">
      <button 
        className="flex items-center space-x-1 hover:text-gray-200"
        onClick={() => toggleSort(sortKey)}
      >
        <span>{label}</span>
        <ArrowUpDown className="w-4 h-4" />
      </button>
    </th>
  );

  return (
    <div className={`space-y-8 ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Vendor Performance Dashboard</h1>
        <div className="flex items-center space-x-4">
          <span className="text-sm text-gray-400">
            {selectedVendors.size} of 5 vendors selected
          </span>
          <button
            onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
            className="p-2 rounded-full bg-gray-800/50 hover:bg-gray-700/50 transition-colors"
          >
            {theme === 'dark' ? <Sun className="text-yellow-400" /> : <Moon className="text-gray-400" />}
          </button>
        </div>
      </div>

      <div className="space-y-6">
        <TabList>
          <Tab selected={activeTab === 'sales'} onClick={() => setActiveTab('sales')}>By Sales</Tab>
          <Tab selected={activeTab === 'purchase'} onClick={() => setActiveTab('purchase')}>By Purchase</Tab>
          <Tab selected={activeTab === 'margin'} onClick={() => setActiveTab('margin')}>By Margin</Tab>
          <Tab selected={activeTab === 'discount'} onClick={() => setActiveTab('discount')}>By Discount</Tab>
        </TabList>

        <motion.div 
          key={activeTab}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={`h-[600px] w-full ${theme === 'dark' ? 'bg-gray-900/90 border-gray-800' : 'bg-white border-gray-200'} border rounded-xl p-6 overflow-y-auto`}
        >
          <ResponsiveContainer height={Math.max(400, chartData.length * 50)}>
            <BarChart
              data={chartData}
              layout="vertical"
              margin={{ top: 5, right: 30, left: 100, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke={theme === 'dark' ? '#374151' : '#E5E7EB'} />
              <XAxis
                type="number"
                stroke={theme === 'dark' ? '#6B7280' : '#4B5563'}
                tick={{ fill: theme === 'dark' ? '#9CA3AF' : '#6B7280', fontSize: 12 }}
              />
              <YAxis
                type="category"
                dataKey="name"
                stroke={theme === 'dark' ? '#6B7280' : '#4B5563'}
                tick={{ fill: theme === 'dark' ? '#9CA3AF' : '#6B7280', fontSize: 12 }}
              />
              <Tooltip
                content={({ active, payload }) => {
                  if (!active || !payload?.length) return null;
                  const data = payload[0].payload;
                  
                  return (
                    <div className={`${theme === 'dark' ? 'bg-gray-900 border-gray-800' : 'bg-white border-gray-200'} border p-4 rounded-lg shadow-lg`}>
                      <p className="text-sm font-medium text-gray-200 mb-2">{data.name}</p>
                      <p className="text-xs text-gray-400">Sales: {formatToLakhs(data.sales)}</p>
                      <p className="text-xs text-gray-400">Purchase: {formatToLakhs(data.purchase)}</p>
                      <p className="text-xs text-gray-400">Margin: {data.margin}%</p>
                      <p className="text-xs text-gray-400">Discount: {data.discount}%</p>
                      <p className="text-xs text-gray-400">Quantity: {data.quantity}</p>
                      <p className="text-xs text-gray-400">Branches: {data.branches}</p>
                    </div>
                  );
                }}
              />
              <Bar dataKey={activeTab}>
                {chartData.map((entry, index) => (
                  <Cell 
                    key={`cell-${index}`}
                    fill={activeTab === 'margin' ? 
                          `hsl(${120 + parseFloat(entry.margin) * 1.5}, 70%, 50%)` :
                          activeTab === 'discount' ?
                          `hsl(${40 + parseFloat(entry.discount) * 2}, 70%, 50%)` :
                          `hsl(${200 + index * 20}, 70%, 50%)`}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </motion.div>
      </div>

      {/* Table Component */}
      <div className={`${theme === 'dark' ? 'bg-gray-900/90 border-gray-800' : 'bg-white border-gray-200'} border rounded-xl overflow-hidden`}>
        <div className="p-4 border-b border-gray-800">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
            <input
              type="text"
              placeholder="Search vendors..."
              className={`w-full pl-10 pr-4 py-2 ${theme === 'dark' ? 'bg-gray-800 border-gray-700 text-gray-200' : 'bg-gray-100 border-gray-200 text-gray-900'} border rounded-lg placeholder-gray-500 focus:outline-none focus:border-blue-500`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className={`${theme === 'dark' ? 'bg-gray-800/50' : 'bg-gray-100'}`}>
                <th className="text-left p-4 text-sm font-medium text-gray-400">Select</th>
                <th className="text-left p-4 text-sm font-medium text-gray-400">Vendor Name</th>
                <SortHeader label="Sales" sortKey="sales" />
                <SortHeader label="Purchase" sortKey="purchase" />
                <SortHeader label="Margin %" sortKey="margin" />
                <SortHeader label="Discount %" sortKey="discount" />
                <SortHeader label="Quantity" sortKey="quantity" />
                <th className="text-left p-4 text-sm font-medium text-gray-400">Branches</th>
              </tr>
            </thead>
            <tbody className={`divide-y ${theme === 'dark' ? 'divide-gray-800' : 'divide-gray-200'}`}>
              {tableData.slice(0, displayCount).map((vendor, index) => (
                <tr key={index} className={`hover:${theme === 'dark' ? 'bg-gray-800/50' : 'bg-gray-100'} transition-colors`}>
                  <td className="p-4">
                    <button
                      onClick={() => toggleVendorSelection(vendor.name)}
                      className={`p-2 rounded-full ${vendor.selected ? 'bg-blue-500' : 'bg-gray-700'} hover:bg-opacity-80 transition-colors`}
                    >
                      {vendor.selected && <Check className="w-4 h-4 text-white" />}
                    </button>
                  </td>
                  <td className="p-4 text-sm text-gray-300">{vendor.name}</td>
                  <td className="p-4 text-sm text-gray-300 text-right">{vendor.salesFormatted}</td>
                  <td className="p-4 text-sm text-gray-300 text-right">{vendor.purchaseFormatted}</td>
                  <td className={`p-4 text-sm text-right ${getColorForValue(vendor.margin, 'margin')}`}>
                    {vendor.margin}%
                  </td>
                  <td className={`p-4 text-sm text-right ${getColorForValue(vendor.discount, 'discount')}`}>
                    {vendor.discount}%
                  </td>
                  <td className="p-4 text-sm text-gray-300 text-right">{vendor.quantity}</td>
                  <td className="p-4 text-sm text-gray-300">{vendor.branches}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {tableData.length > displayCount && (
          <div className="p-4 flex justify-center">
            <button
              onClick={() => setDisplayCount(prev => prev + 10)}
              className={`px-4 py-2 ${theme === 'dark' ? 'bg-gray-800/50 text-white' : 'bg-gray-100 text-gray-900'} rounded-lg hover:bg-gray-700/50 transition-colors`}
            >
              Load More
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default VendorAnalysisChart;