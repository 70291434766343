// Dashboard.jsx
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AnimatePresence, motion} from 'framer-motion';
import {AlertCircle, ListFilter, Plus} from 'lucide-react';
import KPIContainer from './KPIContainer';
import KPIFlyout from './KPIFlyout';
import Layout from './Layout';
import DashboardHero from './presentational/DashboardHero';
import MonthlySummary from './presentational/MonthlySummary';
import DashboardSkeleton from './skeletons/DashboardSkeleton';
import WhatsAppAnalytics from './WhatsAppAnalytics';
import {addToSelectedKPIs, removeFromSelectedKPIs, setError, setKpis, setLoading} from '../redux/features/kpiSlice';
import {kpiService} from "../utils/axios";

const Dashboard = () => {
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);
  const dispatch = useDispatch();
  const { kpis, selectedKPIs, loading, error } = useSelector((state) => state.kpi);
  const {user: {organization_id}} = useSelector(state => state.user);

  useEffect(() => {
    const fetchKPIs = async () => {
      if (kpis.length > 0) return;
      dispatch(setLoading(true));
      try {
        const data = await kpiService.getKPIs({
          organization_id: organization_id
        });
        setTimeout(() => dispatch(setKpis([...data])), 1500);
      } catch (err) {
        dispatch(setError(err.message));
      }
    };
  
    fetchKPIs();
  }, [kpis, dispatch, organization_id]);
  

  const handleKPISelect = (kpi) => {
    const isSelected = selectedKPIs.some(selected => selected.id === kpi.id);

    if (isSelected) {
      dispatch(removeFromSelectedKPIs(kpi));
    } else {
      dispatch(addToSelectedKPIs(kpi));
      setTimeout(() => {
        const container = document.getElementById('kpi-container');
        container && window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: 'smooth'
        });
      }, 100);
    }
  };

  if (loading) return <Layout><DashboardSkeleton /></Layout>;

  if (error) {
    return (
      <Layout>
        <div className="min-h-screen flex items-center justify-center">
          <motion.div 
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-red-500/10 backdrop-blur-xl border border-red-500/20 rounded-2xl p-6 flex items-center gap-4 text-red-400"
          >
            <AlertCircle className="w-6 h-6" />
            <p className="text-lg font-medium">Error: {error}</p>
          </motion.div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <DashboardHero />
      <div id="dashboard" className="relative">
        <motion.div 
          className="flex-grow"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="fixed left-1/2 transform -translate-x-1/2 top-24 z-50">
            <div className="flex items-center gap-3">
              <AnimatePresence>
                <motion.button
                    onClick={() => {
                      setIsFlyoutOpen(!isFlyoutOpen)
                    }}
                  className="group flex items-center gap-2 bg-gray-800/80 backdrop-blur-xl border border-gray-700/50 text-white rounded-xl shadow-lg hover:shadow-blue-500/10 transition-all duration-300"
                    id="flyout-toggle" // DO NOT REMOVE THIS ID
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <div className="h-10 w-10 flex items-center justify-center">
                    {selectedKPIs.length === 0 ? (
                      <Plus className="w-4 h-4 text-blue-400 group-hover:text-blue-300 transition-colors" />
                    ) : (
                      <ListFilter className="w-4 h-4 text-blue-400 group-hover:text-blue-300 transition-colors" />
                    )}
                  </div>
                  <span className="pr-3 text-sm font-medium text-gray-300">
                    {selectedKPIs.length === 0 ? 'Add KPIs' : 'Manage KPIs'}
                  </span>
                </motion.button>
              </AnimatePresence>
              
              <WhatsAppAnalytics />
            </div>
          </div>

          <KPIFlyout
            isOpen={isFlyoutOpen}
            onClose={() => setIsFlyoutOpen(false)}
            kpis={Array.from(kpis)}
            selectedKPIs={selectedKPIs}
            onKPISelect={handleKPISelect}
          />

          <main className="max-w-8xl mx-auto px-8 py-12 space-y-12">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <MonthlySummary />
              <section id="kpi-container" className="h-full pt-8">
                <KPIContainer
                  onRemoveKPI={handleKPISelect}
                  onAddKPI={() => setIsFlyoutOpen(true)}
                />
              </section>
            </motion.div>
          </main>
        </motion.div>
      </div>
    </Layout>
  );
};

export default Dashboard;