import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Bot, HelpCircle, MessageSquare, LogIn, ChevronRight, Menu, X, BarChart2, Lock, Sparkles } from 'lucide-react';
import JaanchLogo from "../components/JaanchLogo";

const navItems = [
  { to: '/', icon: Bot, text: 'Home' },
  { 
    to: '/solutions', 
    icon: BarChart2, 
    text: 'Solutions',
    dropdown: [
      { to: '/solutions/', text: 'Manufacturing' },
      { to: '/solutions/', text: 'E-Commerce' },
      { to: '/solutions/', text: 'Logistics' },
      { to: '/solutions/', text: 'Hospitality' }
    ]
  },
  { to: '/pricing', icon: Lock, text: 'Pricing' },
  { to: '/faq', icon: HelpCircle, text: 'FAQ' },
];

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    industry: 'Manufacturing',
    phone: '',
    message: ''
  });

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 10);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleDropdownEnter = (index) => {
    setActiveDropdown(index);
  };

  const handleDropdownLeave = () => {
    setActiveDropdown(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleDemoSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError(null);

    try {
      // Create the email content
      const emailContent = `
        New Demo Request:
        
        Name: ${formData.name}
        Email: ${formData.email}
        Company: ${formData.company}
        Industry: ${formData.industry}
        Phone: ${formData.phone || 'Not provided'}
        Message: ${formData.message || 'Not provided'}
        
        Submitted on: ${new Date().toLocaleString()}
      `;

      // Send the form data to your email using a serverless function or API
      const response = await fetch('https://your-api-endpoint/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: 'gopalsinghpanwar411@gmail.com',
          subject: `New Demo Request from ${formData.name} at ${formData.company}`,
          text: emailContent,
          formData: formData
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      // Show success state
      setSubmitSuccess(true);
      
      // Reset form after 3 seconds
      setTimeout(() => {
        setShowDemoModal(false);
        setSubmitSuccess(false);
        setFormData({
          name: '',
          email: '',
          company: '',
          industry: 'Manufacturing',
          phone: '',
          message: ''
        });
      }, 3000);

    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitError('There was an error submitting your request. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <header className={`fixed top-0 left-0 right-0 z-40 transition-all duration-300 ${
        isScrolled ? 'bg-gray-900/90 backdrop-blur-xl border-b border-gray-800/50 shadow-lg' : 'bg-transparent'
      }`}>
        <div className="container mx-auto px-4">
          <div className="h-20 flex items-center justify-between">
            <Link to="/" className="flex items-center gap-2">
              <div className="text-xl font-bold">
                <JaanchLogo className="h-8" />
              </div>
            </Link>

            <nav className="hidden md:flex items-center gap-6">
              {navItems.map((item, i) => (
                <div 
                  key={i} 
                  className="relative"
                  onMouseEnter={() => item.dropdown && handleDropdownEnter(i)}
                  onMouseLeave={handleDropdownLeave}
                >
                  <Link
                    to={item.to}
                    className="group flex items-center gap-2 text-sm font-medium text-gray-300 hover:text-white transition-colors py-2"
                  >
                    <item.icon className="w-4 h-4" />
                    <span>{item.text}</span>
                    {item.dropdown && (
                      <ChevronRight className={`w-4 h-4 transition-transform duration-200 ${
                        activeDropdown === i ? 'rotate-90' : ''
                      }`} />
                    )}
                  </Link>
                  
                  {item.dropdown && activeDropdown === i && (
                    <motion.div 
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      className="absolute top-full left-0 mt-1 bg-gray-800 rounded-xl overflow-hidden shadow-xl border border-gray-700/50 w-56 z-50"
                    >
                      <div className="py-2">
                        {item.dropdown.map((dropItem, idx) => (
                          <Link
                            key={idx}
                            to={dropItem.to}
                            className="flex items-center px-4 py-2.5 text-sm text-gray-300 hover:text-white hover:bg-gray-700/50 transition-colors"
                          >
                            <span>{dropItem.text}</span>
                          </Link>
                        ))}
                      </div>
                    </motion.div>
                  )}
                </div>
              ))}
            </nav>

            <div className="hidden md:flex items-center gap-4">
              <Link to="/login" className="text-sm font-medium text-gray-300 hover:text-white transition-colors px-4 py-2 border border-gray-700 rounded-lg hover:border-gray-600">
                Sign In
              </Link>
              
              <motion.button
                onClick={() => setShowDemoModal(true)}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-500 rounded-lg text-white text-sm font-medium hover:from-blue-600 hover:to-purple-600 shadow-md shadow-blue-500/20"
              >
                <span className="flex items-center gap-2">
                  <Sparkles className="w-4 h-4" />
                  Request Demo
                </span>
              </motion.button>
            </div>

            <button 
              onClick={() => setIsOpen(!isOpen)}
              className="p-2 md:hidden text-gray-400 hover:text-white"
            >
              {isOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
            </button>
          </div>
        </div>

        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="border-t border-gray-800/50 bg-gray-900/95 backdrop-blur-xl md:hidden overflow-hidden"
            >
              <nav className="container mx-auto p-4">
                <div className="flex flex-col gap-2">
                  {navItems.map((item, i) => (
                    <div key={i} className="flex flex-col">
                      <Link
                        to={item.to}
                        onClick={() => !item.dropdown && setIsOpen(false)}
                        className="flex items-center justify-between gap-3 p-3 rounded-lg hover:bg-gray-800/50 text-gray-300 hover:text-white transition-colors"
                      >
                        <div className="flex items-center gap-3">
                          <item.icon className="w-5 h-5" />
                          <span className="font-medium">{item.text}</span>
                        </div>
                        {item.dropdown && (
                          <button 
                            onClick={(e) => {
                              e.preventDefault();
                              setActiveDropdown(activeDropdown === i ? null : i);
                            }}
                          >
                            <ChevronRight className={`w-5 h-5 transition-transform duration-200 ${
                              activeDropdown === i ? 'rotate-90' : ''
                            }`} />
                          </button>
                        )}
                      </Link>
                      
                      {item.dropdown && activeDropdown === i && (
                        <div className="ml-5 pl-4 border-l border-gray-700/50 mt-1 mb-2 space-y-1">
                          {item.dropdown.map((dropItem, idx) => (
                            <Link
                              key={idx}
                              to={dropItem.to}
                              onClick={() => setIsOpen(false)}
                              className="flex items-center p-2 text-sm text-gray-400 hover:text-white transition-colors"
                            >
                              <span>{dropItem.text}</span>
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                  
                  <div className="pt-4 mt-2 border-t border-gray-800/50 space-y-3">
                    <Link 
                      to="/login"
                      onClick={() => setIsOpen(false)}
                      className="flex items-center justify-center gap-2 p-3 rounded-lg border border-gray-700 text-gray-300 font-medium hover:bg-gray-800 hover:text-white transition-colors"
                    >
                      <LogIn className="w-5 h-5" />
                      Sign In
                    </Link>
                    
                    <button 
                      onClick={() => {
                        setIsOpen(false);
                        setShowDemoModal(true);
                      }}
                      className="w-full p-3 bg-gradient-to-r from-blue-500 to-purple-500 rounded-lg text-white font-medium hover:from-blue-600 hover:to-purple-600 flex items-center justify-center gap-2"
                    >
                      <Sparkles className="w-5 h-5" />
                      Request Demo
                    </button>
                  </div>
                </div>
              </nav>
            </motion.div>
          )}
        </AnimatePresence>
        
        {/* Early access badge */}
        <div className="hidden lg:block absolute top-0 right-12 px-3 py-1 bg-gradient-to-r from-emerald-500 to-blue-500 text-white text-xs font-bold rounded-b-lg shadow-md transform translate-y-0 hover:translate-y-1 transition-transform duration-300">
          Early Access Program
        </div>
      </header>
      
      {/* Free consultation floating button */}
      <motion.button
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1 }}
        onClick={() => setShowDemoModal(true)}
        className="fixed bottom-6 right-6 z-30 flex items-center gap-2 px-4 py-3 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full text-white font-medium shadow-lg hover:shadow-xl hover:scale-105 transition-all duration-300 md:hidden"
      >
        <MessageSquare className="w-5 h-5" />
        <span>Talk to an Expert</span>
      </motion.button>
      
      {/* Demo request modal */}
      <AnimatePresence>
        {showDemoModal && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
              onClick={() => setShowDemoModal(false)}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                className="bg-gray-900 border border-gray-800 rounded-2xl shadow-xl max-w-md w-full max-h-[90vh] overflow-auto"
                onClick={e => e.stopPropagation()}
              >
                <div className="p-6">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="text-xl font-bold text-white">Request a Free Demo</h3>
                    <button 
                      onClick={() => setShowDemoModal(false)}
                      className="text-gray-400 hover:text-white transition-colors"
                    >
                      <X className="w-5 h-5" />
                    </button>
                  </div>
                  
                  {submitSuccess ? (
                    <div className="text-center py-8">
                      <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-green-500/20 text-green-500 mb-4">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                          <polyline points="22 4 12 14.01 9 11.01"></polyline>
                        </svg>
                      </div>
                      <h4 className="text-lg font-bold text-white mb-2">Thank You!</h4>
                      <p className="text-gray-400">Your demo request has been submitted successfully. Our team will contact you shortly.</p>
                    </div>
                  ) : (
                    <form onSubmit={handleDemoSubmit} className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-1">Name</label>
                        <input
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                          required
                          className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                          placeholder="Your name"
                        />
                      </div>
                      
                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-1">Email</label>
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                          className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                          placeholder="you@company.com"
                        />
                      </div>
                      
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-400 mb-1">Company</label>
                          <input
                            type="text"
                            name="company"
                            value={formData.company}
                            onChange={handleInputChange}
                            required
                            className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                            placeholder="Company name"
                          />
                        </div>
                        
                        <div>
                          <label className="block text-sm font-medium text-gray-400 mb-1">Industry</label>
                          <select
                            name="industry"
                            value={formData.industry}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                          >
                            <option value="Manufacturing">Manufacturing</option>
                            <option value="E-Commerce">E-Commerce</option>
                            <option value="Logistics">Logistics</option>
                            <option value="Hospitality">Hospitality</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </div>
                      
                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-1">Phone (optional)</label>
                        <input
                          type="tel"
                          name="phone"
                          value={formData.phone}
                          onChange={handleInputChange}
                          className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                          placeholder="Your phone number"
                        />
                      </div>
                      
                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-1">How can we help you?</label>
                        <textarea
                          name="message"
                          value={formData.message}
                          onChange={handleInputChange}
                          rows="3"
                          className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                          placeholder="Tell us about your data challenges..."
                        ></textarea>
                      </div>
                      
                      {submitError && (
                        <div className="p-3 bg-red-900/50 border border-red-700 rounded-lg text-red-200 text-sm">
                          {submitError}
                        </div>
                      )}
                      
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`w-full px-4 py-3 bg-gradient-to-r from-blue-500 to-purple-500 rounded-lg text-white font-medium transition-colors ${isSubmitting ? 'opacity-70 cursor-not-allowed' : 'hover:from-blue-600 hover:to-purple-600'}`}
                      >
                        {isSubmitting ? (
                          <span className="flex items-center justify-center gap-2">
                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Processing...
                          </span>
                        ) : (
                          "Schedule My Demo"
                        )}
                      </button>
                      
                      <p className="text-xs text-gray-500 text-center">
                        By submitting this form, you agree to our <a href="/privacy" className="text-blue-400 hover:underline">Privacy Policy</a> and <a href="/terms" className="text-blue-400 hover:underline">Terms of Service</a>.
                      </p>
                    </form>
                  )}
                </div>
              </motion.div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default Header;